const Logo = ({ inverted = true }) => {
  let className = "absolute w-10 h-8 inset-6";
  if (inverted) {
    className = `${className} invert`;
  }
  return (
    <picture>
      <source
        className={className}
        type="image/webp"
        srcSet={process.env.PUBLIC_URL + "/Litesport.Symbol.RGB.White.webp"}
      />
      <img
        className={className}
        src={process.env.PUBLIC_URL + "/Litesport.Symbol.RGB.White.png"}
        alt="Liteboxer Logo"
      />
    </picture>
  );
};

export default Logo;
