import * as React from "react";
import { PageContainer } from "../../shared/PageContainer";
import { Title } from "../../shared/Title";
import { Header } from "../../shared/Header";
import { LoginWidget } from "./LoginWidget";
import { Link, useNavigate } from "react-router-dom";
import { findSubscriptions } from "../plan/PlanApi";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";

export const LoginPage = () => {
  React.useEffect(() => {
    if (window.analytics) {
      window.analytics.page("Members - Login", {
        source: "memberPortal",
        fbp: GetFBPcookie(),
      });
    }
  }, []);

  const navigate = useNavigate();

  const onSuccess = (accessToken: string) => {
    findSubscriptions(accessToken).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          // @TODO: Update this account to denote if we should upsell, also show my account link
          // navigate("/lb_member");
          if (data !== null) {
            navigate("/my-account");
          } else {
            navigate("/lb_member");
          }
        });
      } else {
        navigate("/lb_member");
      }
    });
  };

  return (
    <div>
      <Header showMenu={false} />
      <PageContainer>
        <Title>Log Into Your Account</Title>
        <LoginWidget onSuccess={onSuccess} />
        <div className={"text-center text-gray-800 mt-4 hover:text-lb-green"}>
          <Link to={"/signup"}>
            Not a member? <span className={"underline"}>Sign up</span>
          </Link>
        </div>
      </PageContainer>
    </div>
  );
};
