import * as React from "react";
import cx from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

interface Props extends React.HTMLProps<HTMLInputElement> {
  label: string;
  showLabel?: boolean;
  type?: string;
  isError: boolean;
}

export const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      label,
      name,
      isError,
      showLabel = false,
      type = "text",
      className = "",
      ...rest
    },
    ref
  ) => {
    const labelClasses = cx(
      "block",
      "text-sm",
      "font-medium",
      "text-gray-700",
      showLabel ? "" : "sr-only"
    );
    return (
      <div className={"relative"}>
        <label htmlFor={id} className={labelClasses}>
          {label}
        </label>
        <div>
          <input
            ref={ref}
            type={type}
            id={id}
            name={name}
            className={cx(
              {
                "focus:ring-green-500 focus:border-green-500": !isError,
                "border-red-300 text-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500":
                  isError,
              },
              "shadow-sm block w-full sm:text-sm border-gray-300 rounded-sm",
              className
            )}
            {...rest}
            aria-describedby={`${name}-description`}
          />
        </div>
        {isError && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
    );
  }
);
