import * as React from "react";
import cx from "classnames";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string | undefined;
  color: "gray" | "green" | "red" | "black";
}
export const Button = ({
  children,
  className,
  color,
  ...rest
}: ButtonProps) => {
  const fullClassNames = cx(
    "inline-flex items-center px-3 py-2 border shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2",
    {
      "border-black-300  text-gray-700 bg-white hover:bg-black-50  focus:ring-black-500":
        color === "black",
      "border-transparent text-gray-100 bg-white hover:bg-black focus:ring-green-500 bg-lb-green font-semibold":
        color === "green",
      "border-gray-300 text-gray-700 bg-white hover:bg-gray-50  focus:ring-gray-500":
        color === "gray",
      "border-red-300  text-red-700 bg-white hover:bg-red-50  focus:ring-red-500":
        color === "red",
    },

    className
  );
  return (
    <button type="button" className={fullClassNames} {...rest}>
      {children}
    </button>
  );
};
