import { Link } from "react-router-dom";

export const PaymentSuccess = () => {
  return (
    <div className={"text-center text-gray-800"}>
      <p className={"mt-4"}>
        Download or login to the Litesport <br /> app to get sweating.
      </p>
      <div className={"mt-4 flex justify-center"}>
        <a
          href="https://apps.apple.com/us/app/liteboxer-boxing-workout/id1513945035"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={process.env.PUBLIC_URL + "/apple_app_store_icon.svg"}
            alt="Apple App Store"
            width="156"
            height="52"
          />
        </a>
      </div>
      <div className={"mt-4 flex justify-center"}>
        <a
          href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.liteboxer.Liteboxer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={process.env.PUBLIC_URL + "/android_app_store_icon.png"}
            alt="Android App Store"
            width="157"
            height="47"
          />
        </a>
      </div>
      <div className={"mt-4"}>
        <Link
          to={"/my-account"}
          replace={true}
          className={"underline hover:text-lb-green "}
        >
          Go to My Account
        </Link>
      </div>

      <p className={"mt-8"}>
        Reach out to{" "}
        <span className={"text-lb-green font-semibold"}>
          support@litesport.com
        </span>{" "}
        with any questions
      </p>
    </div>
  );
};
