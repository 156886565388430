import { baseUrl } from "../../shared/Http";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";
import { Cart, CartProduct, getChildProductsFromCart } from "../store/StoreApi";

export const identify = async (email: string) => {
  return fetch(`${baseUrl}/identity/external_id`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      fbp: GetFBPcookie(),
    }),
  }).then((response) => {
    return response.json() as Promise<{ email: string; uuid: string }>;
  });
};

export const getGoogleClientId = () => {
  return new Promise<string>((resolve) => {
    // gtag only makes 'ga' function available after the library loads
    // so we have to stub it if undefined here
    window.ga =
      window.ga ||
      function () {
        (window.ga.q = window.ga.q || []).push(arguments); //eslint-disable-line
      };
    window.ga.l = +new Date();

    const fallback = window.setTimeout(function () {
      //after 4 seconds, assume the script is blocked
      resolve("");
    }, 4000);
    window.ga(function () {
      // this function is called after GA library initializes
      window.clearTimeout(fallback);
      const tracker = window.ga.getAll()[0];
      const clientId = tracker && tracker.get("clientId");

      return resolve(clientId);
    });
  });
};

type OrderCompletedProduct = {
  product_id: string;
  sku: string;
  category?: string;
  name: string;
  brand?: string;
  price: number;
  quantity: number;
  coupon?: string;
  position: number;
  url?: string;
  image_url?: string;
};

export type OrderCompletedProperties = {
  checkout_id: string | null;
  order_id: string | null;
  affiliation?: string;
  subtotal: number;
  total: number;
  revenue?: number;
  shipping: number;
  tax: number;
  discount: number;
  coupon?: string;
  currency: string;
  products?: OrderCompletedProduct[];
};

export const cartToOrderCompleted = (cart: Cart): OrderCompletedProperties => {
  let properties = {
    order_id: null,
    checkout_id: null,
    subtotal: cart.subtotal,
    total: cart.total ? cart.total : 0,
    tax: cart.tax ? cart.tax : 0,
    shipping: cart.shipping ? cart.shipping : 0,
    currency: "USD",
    discount: 0,
    fbp: GetFBPcookie(),
  };
  if (cart.items) {
    properties = Object.assign(
      properties,
      cartLineItemsToDiscountAndProducts(getChildProductsFromCart(cart))
    );
  }

  return properties;
};

type CartDiscountAndProducts = {
  discount: number;
  products: OrderCompletedProduct[];
};
export const cartLineItemsToDiscountAndProducts = (
  items: CartProduct[]
): CartDiscountAndProducts => {
  let discount = 0;
  let position = 0;

  const products = items.map((item) => {
    position++;
    if (item.comparePrice) {
      discount += item.comparePrice - item.checkoutPrice;
    }
    return {
      product_id: item.productId.toString(),
      sku: item.sku,
      name: item.name,
      price: item.price ? item.price : 0,
      quantity: 1,
      position: position,
    };
  });

  return { discount: discount, products: products };
};

export const trackOrderCompleted = (cart: Cart): void => {
  if (window.analytics) {
    const orderProperties = cartToOrderCompleted(cart);
    window.analytics.track("Order Completed", orderProperties);

    const cartItems = getChildProductsFromCart(cart);

    const orderHasLiteboxer =
      cartItems.filter(
        (i) => i.sku.startsWith("SI-LB") && !i.sku.startsWith("SI-LBGO")
      ).length > 0;
    if (orderHasLiteboxer) {
      window.analytics.track("Liteboxer Ordered", orderProperties);
    }

    const orderHasWallMount =
      cartItems.filter((i) => i.sku.startsWith("SI-LBWM")).length > 0;
    if (orderHasWallMount) {
      window.analytics.track("Wall Mount Ordered", orderProperties);
    }

    const orderHasFloorStand =
      cartItems.filter(
        (i) =>
          i.sku.startsWith("SI-LB") &&
          !i.sku.startsWith("SI-LBGO") &&
          !i.sku.startsWith("SI-LBWM")
      ).length > 0;
    if (orderHasFloorStand) {
      window.analytics.track("Floor Stand Ordered", orderProperties);
    }

    const orderHasGo =
      cartItems.filter((i) => i.sku.startsWith("SI-LBGO")).length > 0;
    if (orderHasGo) {
      window.analytics.track("Go Ordered", orderProperties);
    }
  }
};
