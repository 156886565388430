import * as React from "react";
import cx from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

interface Props extends React.HTMLProps<HTMLSelectElement> {
  label: string;
  isError: boolean;
  children: React.ReactNode[];
  className?: string;
}

export const Select = React.forwardRef<HTMLSelectElement, Props>(
  (
    { id, label, name, children, placeholder, isError, className, ...rest },
    ref
  ) => {
    return (
      <div className={"relative"}>
        <label
          htmlFor={id}
          className="block text-sm font-medium text-gray-700 sr-only"
        >
          {label}
        </label>
        <select
          ref={ref}
          id={id}
          name={name}
          className={cx(
            {
              "focus:ring-green-500 focus:border-green-500": !isError,
              "border-red-300 text-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500":
                isError,
            },
            "shadow-sm block w-full sm:text-sm border-gray-300 rounded-sm",
            className
          )}
          {...rest}
          aria-describedby={`${name}-description`}
        >
          <option value="" disabled selected>
            {placeholder}
          </option>
          {children}
        </select>
        {isError && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
    );
  }
);
