import { baseUrl } from "../../shared/Http";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";

export const login = async (email: string) => {
  return fetch(`${baseUrl}/auth/login/email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email.trim(),
      fbp: GetFBPcookie(),
    }),
  });
};

export type SignupSuccess = {
  accessToken: string;
  displayName: string;
  firstName: string;
  lastName: string;
  uuid: string;
  session: string;
};

export type AuthSuccess = {
  accessToken: string;
  displayName: string;
  firstName: string;
  lastName: string;
  uuid: string;
};

export type VerifySuccess = {
  uuid: string;
  email: string;
};

export const verifyEmail = (session: string, code: string, email: string) => {
  return fetch(`${baseUrl}/auth/email/verify`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email.trim(),
      code: code.trim(),
      session: session.trim(),
      fbp: GetFBPcookie(),
    }),
  });
};

export const logout = () => {
  return fetch(`${baseUrl}/auth/logout`);
};

export const signupWithEmail = (
  email: string,
  firstName: string,
  lastName: string,
  displayName: string,
  acceptsMarketing: boolean,
  fbp: string,
  deviceType?: string
) => {
  if (typeof deviceType === "undefined") {
    deviceType = "";
  }

  return fetch(`${baseUrl}/auth/signup/email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "lb-src": deviceType,
    },
    body: JSON.stringify({
      email: email.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      displayName: displayName.trim(),
      acceptsMarketing: acceptsMarketing,
      fbp: GetFBPcookie(),
    }),
  });
};

export const verifyUserByEmail = (email: string) => {
  return fetch(`${baseUrl}/auth/verify_user/email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "lb-src": "VR",
    },
    body: JSON.stringify({
      email: email.trim(),
    }),
  });
};
