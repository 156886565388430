import { Link } from "react-router-dom";
import styles from "./LandingPage.module.css";
import cx from "classnames";
import { useEffect } from "react";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";
import Logo from "../../shared/Logo";

export const LandingPage = () => {
  useEffect(() => {
    if (window.analytics) {
      window.analytics.page("Members - Home", {
        source: "memberPortal",
        fbp: GetFBPcookie(),
      });
    }
  }, []);
  return (
    <div className="min-h-screen bg-white relative">
      <div className="absolute inset-0">
        <picture className={"h-full w-full object-cover"}>
          <source
            className={"h-full w-full object-cover"}
            media="(max-width: 640px)"
            type="image/webp"
            srcSet={process.env.PUBLIC_URL + "/Membership-Portal-Mobile.webp"}
          />
          <source
            className={"h-full w-full object-cover"}
            media="(max-width: 640px)"
            srcSet={process.env.PUBLIC_URL + "/Membership-Portal-Mobile.jpg"}
          />
          <source
            className={"h-full w-full object-cover"}
            media="(max-width: 1024px)"
            type="image/webp"
            srcSet={process.env.PUBLIC_URL + "/Membership-Portal-Desktop.webp"}
          />
          <source
            className={"h-full w-full object-cover"}
            media="(max-width: 1024px)"
            srcSet={process.env.PUBLIC_URL + "/Membership-Portal-Desktop.jpg"}
          />
          <source
            className={"h-full w-full object-cover"}
            type="image/webp"
            srcSet={
              process.env.PUBLIC_URL + "/Membership-Portal-Desktop-xl.webp"
            }
          />
          <img
            className={"h-full w-full object-cover"}
            src={process.env.PUBLIC_URL + "/Membership-Portal-Desktop-xl.jpg"}
            alt="Litesport fighter"
          />
        </picture>
        <div
          className={cx(
            "absolute inset-0 mix-blend-multiply",
            styles.backgroundGrad
          )}
        />
      </div>
      <Logo inverted={false} />

      <div className="min-h-screen flex px-4 sm:px-6 lg:px-8 items-center justify-center">
        <div className="relative">
          <div className="relative h-full">
            <h2 className="mt-6 text-4xl font-extrabold text-gray-100 text-center">
              Step Into The Ring with Litesport
            </h2>
            <h3 className="text-xl font-semibold text-gray-100 mt-6 text-center">
              Unlock your 7 day trial today
            </h3>

            <p className="text-gray-100 mt-6 text-center max-w-md mx-auto">
              New content added daily, unlimited access to Punch Tracks
              featuring top-charting artists and 300+ Trainer Classes.
            </p>
            <div className={"mt-6 text-center px-16"}>
              <a
                href="https://litesport.com/trial"
                className="hover:text-gray-100 hover:bg-lb-green tracking-wide flex justify-center p-4 border border-transparent rounded-sm shadow-sm text-sm font-semibold text-black bg-gray-100  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                SIGN UP NOW
              </a>
            </div>
            <p className="text-sm text-gray-100 mt-4 text-center max-w-md mx-auto italic">
              Your trial will begin when you receive
              <br />
              and activate your Liteboxer
              <br />
              or 7 days after signup (whichever comes first).
              <br />
              Cancel at anytime via the Litesport Member Portal.
            </p>
            <div className={"mt-6 text-center"}>
              <Link
                to={"login"}
                className={"hover:text-lb-green text-gray-100 underline"}
              >
                Already a member or Litesport app user? Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
