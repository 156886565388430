import { useAuthToken } from "../../shared/AuthContext";
import { useQuery } from "react-query";
import * as profileApi from "./ProfileApi";
import { Profile } from "./ProfileApi";

export const useProfile = () => {
  const token = useAuthToken();
  return useQuery<Profile>(
    ["profile"],
    () => {
      return profileApi.getProfile(token!);
    },
    {
      enabled: !!token,
    }
  );
};
