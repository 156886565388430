import * as React from "react";
import { PageContainer } from "../../shared/PageContainer";
import { Link } from "react-router-dom";
import { Title } from "../../shared/Title";
import { Header } from "../../shared/Header";
import { SignupWidget } from "./SignupWidget";
import { useNavigate } from "react-router";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";

export const SignupPage = () => {
  React.useEffect(() => {
    if (window.analytics) {
      window.analytics.page("Members - Signup", {
        source: "memberPortal",
        fbp: GetFBPcookie(),
      });
    }
  }, []);

  const navigate = useNavigate();
  const onSuccess = () => navigate("/lb_member");

  return (
    <div>
      <Header showMenu={false} />
      <PageContainer>
        <Title>Create Your Account</Title>
        <div className={"px-4 sm:px-6 lg:px-8 mx-auto"}>
          <div className={"text-center text-gray-800 hover:text-lb-green"}>
            <Link to={"/login"}>
              Already a member or Litesport app user?{" "}
              <span className={"underline"}>Login</span>
            </Link>
          </div>
          <SignupWidget onSuccess={onSuccess} />
        </div>
      </PageContainer>
    </div>
  );
};
