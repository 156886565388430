import * as React from "react";
import cx from "classnames";
import styles from "./LiteboxerGoLandingPage.module.css";
import { Product } from "../store/StoreApi";
import { LiteboxerGoProductPage } from "./LiteboxerGoProductPage";
import { LiteboxerGoChoosePlanPage } from "./LiteboxerGoChoosePlanPage";
import { LiteboxerGoCartPage } from "./LiteboxerGoCartPage";
import Logo from "../../shared/Logo";

enum PreCheckoutSteps {
  LandingPage,
  PlanSelection,
  Cart,
}

const STEPS = Object.keys(PreCheckoutSteps).length / 2;

export const LiteboxerGoLandingPage = () => {
  const [currentStep, setStep] = React.useState(0);
  const [product, setProduct] = React.useState<Product | undefined>(undefined);

  const nextStep = () => {
    if (currentStep < STEPS) {
      setStep(currentStep + 1);
    }
  };

  // const prevStep = () => {
  //   if (currentStep > 0) {
  //     setStep(currentStep - 1);
  //   }
  // };

  const nextStepSetProduct = (p: Product) => {
    setProduct(p);
    nextStep();
  };

  const StepBreadcrumbs = () => {
    const activeStyles = "border-lb-green text-lb-green";
    const inactiveStyles = "border-black text-black";
    const steps = Object.values(PreCheckoutSteps).filter(
      (v) => typeof v === "number"
    );
    return (
      <div className="relative h-6 mt-16 text-center justify-center">
        {steps.map((_, idx) => {
          return (
            <React.Fragment key={idx}>
              {idx > 0 && (
                <div
                  className={cx(
                    "inline-block w-16 border-t relative",
                    styles.breadcrumbBorder,
                    idx <= currentStep ? activeStyles : inactiveStyles
                  )}
                />
              )}
              <div
                className={cx(
                  "inline-block w-7 h-7 rounded-full border text-center justify-center",
                  idx <= currentStep ? activeStyles : inactiveStyles
                )}
              >
                <span
                  className={cx(
                    "font-sans relative text-xs bottom-px",
                    idx <= currentStep ? activeStyles : inactiveStyles
                  )}
                >
                  {idx + 1}
                </span>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  type StepProps = {
    visibleStep: number;
    children: React.ReactNode;
  };

  const StepContent = ({ visibleStep, children }: StepProps) => {
    if (currentStep !== visibleStep) {
      return <></>;
    }

    return <>{children}</>;
  };

  return (
    <div
      className={cx(
        "min-h-screen bg-lg-go-light-gray relative",
        `step-${currentStep}`
      )}
    >

      <Logo />
      <div className="flex pt-1 max-w-2xl md:mx-auto items-center justify-center">
        <div className="mx-auto relative w-full">
          <StepBreadcrumbs />
          <StepContent visibleStep={0}>
            <LiteboxerGoProductPage next={nextStep} />
          </StepContent>
          <StepContent visibleStep={1}>
            <LiteboxerGoChoosePlanPage choose={nextStepSetProduct} />
          </StepContent>
          <StepContent visibleStep={2}>
            <LiteboxerGoCartPage product={product} />
          </StepContent>
        </div>
      </div>
    </div>
  );
};
