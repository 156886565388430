import React from "react";
import { useAuth } from "./AuthContext";
import { LoginPage } from "../features/auth/LoginPage";
import { Header } from "./Header";

type ContentSectionProps = {
  showMenu?: boolean;
  children: React.ReactNode;
};
export const ContentSection = ({
  showMenu = true,
  children,
}: ContentSectionProps) => {
  const { token } = useAuth();
  if (!token) {
    return <LoginPage />;
  }
  return (
    <div>
      <Header showMenu={showMenu} />
      {children}
    </div>
  );
};
