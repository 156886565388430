import * as React from "react";
import cx from "classnames";
import { SubmitHandler, useForm } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import * as authApi from "./AuthApi";
import { useAuth } from "../../shared/AuthContext";
import { LiteboxerButton } from "../../shared/LiteboxerButton";
import { ErrorAlert } from "../../shared/ErrorAlert";
import { InputError } from "../../shared/InputError";
import { useQueryClient } from "react-query";
import * as profileApi from "../profile/ProfileApi";
import { useState } from "react";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";

type LoginForm = {
  email: string;
};

type ErrorDetails = {
  status: string;
  error: { error: string; email?: string; code?: string };
};

type LoginWidgetProps = {
  onSuccess?: (accessToken: string) => void;
  email?: string;
};

type LoginState = {
  email: string;
  session: string;
};

export const LoginWidget = ({ onSuccess, email }: LoginWidgetProps) => {
  const [session, setSession] = useState<LoginState | undefined>(undefined);
  if (session === undefined) {
    return (
      <EnterEmailForm
        onSuccess={(newSession, email) => {
          setSession({ email, session: newSession });
        }}
        email={email}
      />
    );
  } else {
    return (
      <EnterCodeForm
        email={session.email}
        setSession={setSession}
        session={session.session}
        onSuccess={(accessToken) => {
          if (onSuccess) {
            onSuccess(accessToken);
          }
        }}
      />
    );
  }
};

const EnterEmailForm = ({
  onSuccess,
  email,
}: {
  onSuccess: (session: string, email: string) => void;
  email?: string;
}) => {
  const [globalError, setGlobalError] = React.useState<undefined | string>(
    undefined
  );
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<LoginForm>({
    defaultValues: {
      email: email ? email : "",
    },
  });

  const onSubmit: SubmitHandler<LoginForm> = (data) => {
    setGlobalError(undefined);
    return authApi.login(data.email).then((response) => {
      if (response.ok) {
        return response.json().then((success: { session: string }) => {
          onSuccess(success.session, data.email);
        });
      } else if (response.status === 400) {
        response.json().then((errorDetails: ErrorDetails) => {
          if (errorDetails.error.email) {
            if (errorDetails.error.email) {
              setError("email", {
                type: "server",
                message: errorDetails.error.email,
              });
            }
          } else {
            setGlobalError(errorDetails.error.error);
          }
        });
      } else {
        setGlobalError("Could not login. Please contact support@litesport.com");
      }
    });
  };
  return (
    <div>
      {globalError ? (
        <ErrorAlert
          heading={globalError}
          onClose={() => {
            setGlobalError(undefined);
          }}
        />
      ) : null}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"mt-3"}>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 sr-only"
          >
            Email
          </label>
          <div className="mt-1 relative">
            <input
              {...register("email", { required: true })}
              type="text"
              name="email"
              id="email"
              className={cx(
                {
                  "focus:ring-green-500 focus:border-green-500": !errors.email,
                  "border-red-300 text-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500":
                    errors.email,
                },
                "shadow-sm  block w-full sm:text-sm border-gray-300 rounded-sm"
              )}
              placeholder="Email Address"
              aria-describedby="email-description"
            />
            {errors.email && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {errors.email && errors.email.type === "required" && (
            <InputError id="email-error">This field is required</InputError>
          )}
          {errors.email && errors.email.type === "server" && (
            <InputError id="email-error">
              {errors.email.message ?? "Unknown error"}
            </InputError>
          )}
        </div>

        <LiteboxerButton
          type={"submit"}
          text={"Send Code"}
          className={"mt-8"}
          isLoading={isSubmitting}
        />
      </form>
    </div>
  );
};

const EnterCodeForm = ({
  onSuccess,
  setSession,
  email,
  session,
}: {
  onSuccess: (accessToken: string) => void;
  setSession: React.Dispatch<React.SetStateAction<LoginState | undefined>>;
  email: string;
  session: string;
}) => {
  const [globalError, setGlobalError] = React.useState<undefined | string>(
    undefined
  );
  const auth = useAuth();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<{ code: string }>({
    defaultValues: {
      code: "",
    },
  });

  const onSubmit: SubmitHandler<{ code: string }> = (data) => {
    setGlobalError(undefined);
    return authApi.verifyEmail(session, data.code, email).then((response) => {
      if (response.ok) {
        return response.json().then((success: authApi.AuthSuccess) => {
          if (window.analytics) {
            window.analytics.identify(success.uuid, {
              firstName: success.firstName,
              lastName: success.lastName,
              displayName: success.displayName,
              email: email,
              source: "memberPortal",
              fbp: GetFBPcookie(),
            });
          }
          auth.setToken(success.accessToken);
          queryClient.prefetchQuery(["profile"], () => {
            return profileApi.getProfile(success.accessToken);
          });
          if (onSuccess) {
            onSuccess(success.accessToken);
          }
        });
      } else if (response.status === 400) {
        response.json().then((errorDetails: ErrorDetails) => {
          if (errorDetails.error.code) {
            if (errorDetails.error.code) {
              setError("code", {
                type: "server",
                message: errorDetails.error.code,
              });
            }
          } else {
            setGlobalError(errorDetails.error.error);
          }
        });
      } else {
        setGlobalError("Could not login. Please contact support@litesport.com");
      }
    });
  };
  const resendCode = () =>
    authApi.login(email).then((response) => {
      if (response.ok) {
        setGlobalError(undefined);
        return response.json().then((success: { session: string }) => {
          setSession({ session: success.session, email: email });
        });
      } else if (response.status === 429) {
        setGlobalError(
          "You're requesting codes too fast. Please wait a minute and try again."
        );
      } else {
        setGlobalError(
          "Could resend code. Please contact support@litesport.com."
        );
      }
    });

  return (
    <div>
      {globalError ? (
        <ErrorAlert
          heading={globalError}
          onClose={() => {
            setGlobalError(undefined);
          }}
        />
      ) : null}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"mt-3"}>
          <label
            htmlFor="code"
            className="block text-sm font-medium text-gray-700 sr-only"
          >
            Code
          </label>
          <div className="mt-1 relative">
            <input
              {...register("code", { required: true })}
              type="text"
              name="code"
              id="code"
              className={cx(
                {
                  "focus:ring-green-500 focus:border-green-500": !errors.code,
                  "border-red-300 text-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500":
                    errors.code,
                },
                "shadow-sm  block w-full sm:text-sm border-gray-300 rounded-sm"
              )}
              placeholder="Code (sent to your email)"
              aria-describedby="code-description"
            />
            {errors.code && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {errors.code && errors.code.type === "required" && (
            <InputError id="code-error">This field is required</InputError>
          )}
          {errors.code && errors.code.type === "server" && (
            <InputError id="code-error">
              {errors.code.message ?? "Unknown error"}
            </InputError>
          )}
        </div>

        <div
          className="relative text-center text-gray-800 mt-2 hover:text-lb-green"
          onClick={() => resendCode()}
        >
          Not seeing a code in your email?{" "}
          <span className="underline">Resend code</span>
        </div>

        <LiteboxerButton
          type={"submit"}
          text={"Login"}
          className={"mt-4"}
          isLoading={isSubmitting}
        />
      </form>
    </div>
  );
};
