import * as React from "react";
import { useNavigate, useParams } from "react-router";
import { useAuth } from "../../shared/AuthContext";
import {
  useCatalog,
  getProducts,
  Product,
  getCurrencyFormatter,
  CatalogGroups,
  Cart,
  CheckoutState,
  addToCart,
} from "../store/StoreApi";
import { Button } from "../../shared/Button";
import { ScrollToTop } from "../../shared/ScrollToTop";
import { PlayIcon } from "@heroicons/react/outline";
import * as membershipApi from "../plan/PlanApi";

export const ManagePlanPage = () => {
  const { token } = useAuth();
  const { data } = useCatalog(token || "", -2);
  const products = getProducts(data);
  const navigate = useNavigate();
  const { membershipId } = useParams();
  const getCurrentProduct = () => {
    if (typeof products === "undefined") {
      return undefined;
    }
    for (const product of products) {
      if (product && product.planChangeIsCurrentPlan) {
        return product;
      }
    }
    return undefined;
  };
  const currentProduct = getCurrentProduct();
  const formatter = getCurrencyFormatter();

  const switchPlan = (p: Product) => {
    if (
      p.renewalTermMonths &&
      currentProduct &&
      currentProduct.renewalTermMonths
    ) {
      var name = p.name
        .replace("Liteboxer Go", "")
        .replace("Liteboxer All-Access", "")
        .replace("Liteboxer VR", "")
        .replace("Litesport Go", "")
        .replace("Litesport All-Access", "")
        .replace("Litesport VR", "")
        .replace(" - ", "")
        .replace("12-Month", "Annual");

      if (currentProduct.renewalTermMonths < p.renewalTermMonths) {
        return `Save with ${name}`;
      } else if (p.planChangeIsCurrentPlan) {
        return `Keep ${name}`;
      } else {
        return `Move to ${name}`;
      }
    } else {
      return `Move to ${p.name}`;
    }
  };

  const scheduleSvg = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M9 20H6C3.79086 20 2 18.2091 2 16V7C2 4.79086 3.79086 3 6 3H17C19.2091 3 21 4.79086 21 7V10"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 2V4"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 2V4"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 8H21"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.5 15.6429L17 17.1429"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="17"
          cy="17"
          r="5"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const upgradeSvg = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-5 h-5 text-white-400 sm:h-9 sm:w-9"
        viewBox="0 0 24 24"
        fill="white"
      >
        <path
          fill="none"
          stroke="#000"
          strokeWidth="2"
          d="M12,18 L12,8 L12,18 Z M12,23 C18.0751322,23 23,18.0751322 23,12 C23,5.92486775 18.0751322,1 12,1 C5.92486775,1 1,5.92486775 1,12 C1,18.0751322 5.92486775,23 12,23 Z M17,12 L12,7 L7,12"
        />
      </svg>
    );
  };

  const checkmarkSvg = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
        version="1"
        enableBackground="new 0 0 48 48"
      >
        <polygon
          fill="#01aa1b"
          points="40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9"
        />
      </svg>
    );
  };

  const goToCheckout = (productId: number) => {
    addToCart(token || "", 0, productId, undefined, membershipId).then(
      (cart: Cart) => {
        const state: CheckoutState = {
          cart: cart,
          sourceGroup: CatalogGroups.PlanChange,
          createAccount: false,
        };

        navigate(`/memberships/${membershipId}/checkout`, {
          state: state,
        });
      }
    );
  };

  return (
    <>
      <ScrollToTop></ScrollToTop>
      <div>
        <div className="container px-6 py-8 mx-auto">
          <p className="text-xl text-center"></p>

          <h1 className="mt-4 text-3xl font-semibold text-center capitalize lg:text-4xl">
            Manage Membership
          </h1>
          <div className="mt-6 space-y-8 xl:mt-12 text-white">
            {data && data.existingMembership && (
              <>
                {data.existingMembership.planType !==
                  ("Liteboxer All-Access" || "Litesport All-Access") && (
                  <div
                    className="flex items-center justify-between max-w-2xl px-8 py-4 mx-auto border cursor-pointer rounded-xl dark:border-gray-700 bg-lb-green"
                    onClick={() =>
                      navigate(`/membership/${membershipId}/upgrade`)
                    }
                  >
                    <div className="flex min-w-24 items-center">
                      {upgradeSvg()}

                      <div className="flex flex-col items-center mx-5 space-y-1">
                        <h2 className="text-lg font-semibold  sm:text-2xl ">
                          Upgrade to All-Access
                        </h2>
                      </div>
                    </div>

                    <h2 className="text-2xl font-semibold  sm:text-4xl ">
                      <span className="text-base font-medium">from </span>{" "}
                      $19.79
                      <span className="text-base font-medium">/mo*</span>
                    </h2>
                  </div>
                )}

                {products &&
                  products.map((product) => {
                    return (
                      <React.Fragment key={product.id}>
                        {membershipId && !product.planChangeIsUpgrade && (
                          <>
                            {!product.planChangeIsCurrentPlan && (
                              <>
                                <div
                                  className="flex items-center justify-between max-w-2xl px-8 py-4 mx-auto border cursor-pointer rounded-xl bg-lb-green"
                                  onClick={() => goToCheckout(product.id || 0)}
                                >
                                  <div className="flex min-w-24 items-center">
                                    {scheduleSvg()}
                                    <div className="flex flex-col items-center mx-5 space-y-1">
                                      <h2 className="text-lg font-semibold text-white sm:text-2xl ">
                                        {switchPlan(product)}
                                      </h2>
                                    </div>
                                  </div>
                                  <h2 className="text-2xl font-semibold  sm:text-4xl ">
                                    {formatter.format(
                                      product.renewalMonthlyRate!
                                    )}
                                    <span className="text-base font-medium">
                                      /mo*
                                    </span>
                                  </h2>
                                </div>
                              </>
                            )}
                          </>
                        )}
                        {product.planChangeIsCurrentPlan && (
                          <>
                            {data.existingMembership.status === "PAUSED" ||
                            (data.existingMembership.status !== "CANCELLED" &&
                              data.existingMembership.pauseOn) ? (
                              <>
                                <div
                                  className="flex items-center justify-between max-w-2xl px-8 py-4 mx-auto border cursor-pointer rounded-xl dark:border-gray-700 bg-black"
                                  onClick={() => {
                                    membershipApi
                                      .unpauseMembership(
                                        data.existingMembership.id,
                                        token!
                                      )
                                      .then((response) => {
                                        navigate(`/my-account`);
                                      });
                                  }}
                                >
                                  <div className="flex min-w-24 items-center">
                                    <div className="sm:h-9">
                                      <PlayIcon
                                        className="h-10 w-10 text-black-500"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </div>

                                  <div className="flex flex-col items-center mx-5 space-y-1">
                                    {data.existingMembership.status !==
                                    "PAUSED" ? (
                                      <>
                                        <h2 className="text-lg font-semibold  sm:text-2xl ">
                                          Cancel Upcoming Pause
                                        </h2>
                                        <p>
                                          {`${data.existingMembership.pauseOn} to ${data.existingMembership.pauseUntil}`}
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <h2 className="text-lg font-semibold  sm:text-2xl ">
                                          Resume Current Plan
                                        </h2>
                                        <p>
                                          {`paused until ${data.existingMembership.pauseUntil}`}
                                        </p>
                                      </>
                                    )}
                                  </div>

                                  <h2 className="text-2xl font-semibold  sm:text-4xl ">
                                    ${data.existingMembership.cost}
                                    <span className="text-base font-medium">
                                      /mo*
                                    </span>
                                  </h2>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="flex items-center justify-between max-w-2xl px-8 py-4 mx-auto border cursor-pointer rounded-xl dark:border-gray-700 bg-black"
                                  onClick={() => navigate(`/my-account`)}
                                >
                                  <div className="flex min-w-24 items-center">
                                    {checkmarkSvg()}
                                    <div className="flex flex-col items-center mx-5 space-y-1">
                                      <h2 className="text-2xl font-semibold ">
                                        {switchPlan(product)}
                                      </h2>
                                    </div>
                                  </div>
                                  <h2 className="text-2xl font-semibold sm:text-4xl ">
                                    {/* <div className="text-base font-medium"> */}
                                    {formatter.format(
                                      product.renewalMonthlyRate!
                                    )}
                                    <span className="text-base font-medium">
                                      /mo*
                                    </span>
                                    {/* </div> */}
                                  </h2>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
              </>
            )}
            {(!data || !products) && (
              <div className="text-black text-center">Loading...</div>
            )}
            {membershipId && (
              <div className="flex justify-center">
                <Button
                  color={"black"}
                  className={"flex justify-center"}
                  onClick={() => navigate(`/my-account`)}
                >
                  BACK
                </Button>
              </div>
            )}
            {membershipId &&
              (data?.existingMembership?.status === "ACTIVE" ||
                data?.existingMembership?.status === "PAST_DUE") &&
              !data?.existingMembership?.pauseOn && (
                <div className="flex justify-center">
                  <Button
                    color={"red"}
                    className={"flex justify-center"}
                    onClick={() =>
                      navigate(`/membership/${membershipId}/pause`)
                    }
                  >
                    Pause Membership
                  </Button>
                </div>
              )}
            {membershipId && (
              <div className="flex justify-center">
                <Button
                  color={"red"}
                  className={"flex justify-center"}
                  onClick={() => navigate(`/membership/${membershipId}/cancel`)}
                >
                  Cancel Membership
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
