import * as React from "react";
import { useNavigate, useParams } from "react-router";
import { useAuth } from "../../shared/AuthContext";
import {
  useCatalog,
  getProducts,
  CatalogGroups,
  Cart,
  CheckoutState,
  addToCart,
  getCurrencyFormatter,
} from "../store/StoreApi";
import { Button } from "../../shared/Button";
export const UpgradePlanPage = () => {
  const { token } = useAuth();
  const { data } = useCatalog(token || "", -2);
  const products = getProducts(data);
  const navigate = useNavigate();
  const { membershipId } = useParams();
  if (!products) {
    return <></>;
  }
  const formatter = getCurrencyFormatter();

  const scheduleSvg = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M9 20H6C3.79086 20 2 18.2091 2 16V7C2 4.79086 3.79086 3 6 3H17C19.2091 3 21 4.79086 21 7V10"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 2V4"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 2V4"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 8H21"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.5 15.6429L17 17.1429"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="17"
          cy="17"
          r="5"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const goToCheckout = (productId: number) => {
    addToCart(token || "", 0, productId, undefined, membershipId).then(
      (cart: Cart) => {
        const state: CheckoutState = {
          cart: cart,
          sourceGroup: CatalogGroups.PlanChange,
          createAccount: false,
        };

        navigate(`/memberships/${membershipId}/checkout`, {
          state: state,
        });
      }
    );
  };

  return (
    <div className="bg-white dark:bg-gray-900">
      <div className="container px-6 py-8 mx-auto">
        <p className="text-xl text-center text-gray-500 dark:text-gray-300"></p>

        <h1 className="mt-4 text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white">
          Upgrade to Litesport All-Access Membership
        </h1>
        <div className="mt-6 space-y-8 xl:mt-12">
          {data && data.existingMembership && (
            <>
              {products.map((product) => {
                return (
                  <>
                    <React.Fragment key={product.id}>
                      {membershipId && product.planChangeIsUpgrade && (
                        <>
                          <div
                            className="flex items-center justify-between max-w-2xl px-8 py-4 mx-auto border cursor-pointer rounded-xl dark:border-gray-700 bg-lb-green"
                            onClick={() => goToCheckout(product.id || 0)}
                          >
                            <div className="flex min-w-24 items-center">
                              {scheduleSvg()}
                              <div className="flex flex-col items-center mx-5 space-y-1">
                                <h2 className="text-lg font-medium text-gray-700 sm:text-2xl dark:text-gray-200">
                                  {product.name
                                    .replace("Liteboxer All-Access", "")
                                    .replace("Litesport All-Access", "")
                                    .replace("12-Month", "Annual")}
                                </h2>
                                <div className="px-2 text-xs text-white bg-black sm:px-4 sm:py-1 ">
                                  {product.renewalTermMonths &&
                                    product.renewalTermMonths === 1 &&
                                    "MOST FLEXIBLE"}
                                  {product.renewalTermMonths &&
                                    product.renewalTermMonths === 12 &&
                                    "MOST POPULAR"}
                                  {product.renewalTermMonths &&
                                    product.renewalTermMonths === 24 &&
                                    "BEST VALUE"}
                                </div>
                              </div>
                            </div>
                            <h2 className="text-2xl font-semibold text-gray-500 sm:text-4xl dark:text-gray-300">
                              {/* <div className="text-base font-medium"> */}
                              {formatter.format(product.renewalMonthlyRate!)}
                              <span className="text-base font-medium">
                                /mo*
                              </span>
                              {/* </div> */}
                            </h2>
                          </div>
                        </>
                      )}
                    </React.Fragment>
                  </>
                );
              })}
            </>
          )}
          {membershipId && (
            <div className="flex justify-center">
              <Button
                color={"black"}
                className={"flex justify-center"}
                onClick={() => navigate(`/membership/${membershipId}/manage`)}
              >
                BACK
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
