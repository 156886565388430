import React, { useRef } from "react";
import * as dropin from "braintree-web-drop-in";
import "./braintreeoverrides.css";
import { ErrorAlert } from "../../shared/ErrorAlert";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";

type Props = {
  onPaymentInstanceChange: (instance: dropin.Dropin | undefined) => void;
  braintreeToken: string;
  extraCreateOptions?: object;
};
const PaymentCardInput = ({
  onPaymentInstanceChange,
  braintreeToken,
  extraCreateOptions,
}: Props) => {
  const [cardInputError, setCardInputError] = React.useState(false);

  const instance = useRef<any>(undefined);
  const braintreeWidgetCreating = useRef<boolean>(false);
  React.useEffect(() => {
    if (instance.current) {
      onPaymentInstanceChange(instance.current);
      return;
    } else if (!braintreeWidgetCreating.current && braintreeToken) {
      braintreeWidgetCreating.current = true;
      dropin.create(
        Object.assign(
          {},
          {
            container: ".braintree-dropin-target",
            authorization: braintreeToken,
            card: { cardholderName: { required: true } },
          },
          extraCreateOptions
        ),
        (err, clientInstance) => {
          clientInstance?.on(
            "paymentMethodRequestable",
            function (event: dropin.PaymentMethodRequestablePayload) {
              if (event.paymentMethodIsSelected) {
                if (window.analytics) {
                  window.analytics.track("Payment Info Entered", {
                    source: "memberPortal",
                    step: 2,
                    shipping_method: "USPS",
                    payment_method: event.type,
                    fbp: GetFBPcookie(),
                  });
                }
              }
            }
          );
          clientInstance?.on(
            "paymentOptionSelected",
            function (event: dropin.PaymentOptionSelectedPayload) {
              if (window.analytics) {
                window.analytics.track("Checkout Step Viewed", {
                  source: "memberPortal",
                  step: 2,
                  shipping_method: "USPS",
                  payment_method: event.paymentOption,
                  fbp: GetFBPcookie(),
                });
              }
            }
          );
          if (err) {
            setCardInputError(true);
          } else {
            instance.current = clientInstance;
            onPaymentInstanceChange(clientInstance);
          }
        }
      );
    }
  }, [braintreeToken, onPaymentInstanceChange, extraCreateOptions]);

  return (
    <>
      {cardInputError ? (
        <ErrorAlert
          heading={
            "Could not contact payment service. Please contact support@litesport.com"
          }
          onClose={() => {
            setCardInputError(false);
          }}
        />
      ) : null}

      <div className={"braintree-dropin-target"} />
    </>
  );
};

export { PaymentCardInput };
