import { baseUrl } from "../../shared/Http";
import { useQuery, useQueryClient } from "react-query";
import { useAuth } from "../../shared/AuthContext";
import { useSearchParams } from "react-router-dom";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";

export type PaymentTokenResponse = {
  clientToken: string;
};

export type ShopifyData = {
  order_number: string;
  order_id: string;
  email: string;
  first_name: string;
  last_name: string;
  return_url: string;
  accepts_marketing: boolean;
};

export const useShopifyData = () => {
  const [params] = useSearchParams();
  const data = params.get("data");
  if (data) {
    return JSON.parse(atob(data)) as ShopifyData;
  }
  return undefined;
};

type signupAndEnrollRequest = {
  nonce: string;
  plan: string;
  username: string;
  password: string;
  verifyPassword: string;
  email: string;
  firstName: string;
  lastName: string;
  acceptsMarketing: boolean;
  orderNumber: string;
  captchaToken: string;
  fbp: string;
};

export const signupAndEnroll = (request: signupAndEnrollRequest) => {
  return fetch(`${baseUrl}/memberships/user`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(request),
  });
};

export type GoAddress = {
  first_name: string;
  last_name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  province: string;
  province_code?: string;
  zip: string;
  phone: string;
};

export type GoAddressAnonymousProps = {
  email: string;
  acceptsMarketing: boolean;
};

export type GoAddressFormData = GoAddress & GoAddressAnonymousProps;

export type CartLineItem = {
  id: number;
  name: string;
  title: string;
  variant_id: number;
  quantity: number;
  price: number | null;
  pre_tax_price: number | null;
  applied_discount: CartAppliedDiscount | null;
  sku: string;
  total_discount: number | null;
};

type CartAppliedDiscount = {
  description: string;
  value_type: string;
  value: string;
  amount: string;
  title: string;
};

export type CartResponse = {
  id: number;
  planId: string; // @TODO: Better GUID type?
  existingMembershipId?: number;
  membershipId?: number;
  shipToAddress?: GoAddress;
  billToAddress?: GoAddress;
  shopifyCustomerId: number;
  draftOrderNumber?: number;
  checkoutNumber?: number;
  orderCompleted?: boolean;
  profileId: number;
  oneTimeCharge: number;
  recurringCharge: number;
  subTotal: number;
  shipping: number;
  tax: number;
  total: number;
  braintreeSubscriptionId?: string;
  braintreeTransactionId?: string;
  hwVariantId: number | null;
  memberVariantId: number | null;
  isUpgrade?: boolean;
  isKeepingCurrentPlan?: boolean;
  googleClientId?: string;
  lineItems?: CartLineItem[];
};

type GoAddressAuthenticatedRequest = {
  shipToAddress: GoAddress;
  fbp: string;
};

type GoAddressAnonymousRequest = {
  email: string;
  acceptsMarketing: boolean;
  shipToAddress: GoAddress;
  fbp: string;
};

export const submitDraftOrderByCartId: (
  token: string | undefined,
  cartId: number,
  address: GoAddress
) => Promise<CartResponse> = (
  token: string | undefined,
  cartId: number,
  address: GoAddress
) => {
  const payload: GoAddressAuthenticatedRequest = {
    shipToAddress: address,
    fbp: GetFBPcookie(),
  };

  return fetch(`${baseUrl}/memberships/order/draft/${cartId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const submitDraftOrderByPlanId: (
  planId: string,
  address: GoAddress,
  email: string,
  acceptsMarketing: boolean
) => Promise<CartResponse> = (
  planId: string,
  address: GoAddress,
  email: string,
  acceptsMarketing: boolean
) => {
  const payload: GoAddressAnonymousRequest = {
    email: email,
    acceptsMarketing: acceptsMarketing,
    shipToAddress: address,
    fbp: GetFBPcookie(),
  };

  return fetch(`${baseUrl}/memberships/order/${planId}/draft`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

type GetCartRequest = {
  existingMembershipId: string | null;
  googleClientId: string;
  fbp: string;
};

export const findCart: (
  token: string,
  planId: string,
  existingMembershipId: string | undefined,
  googleClientId: string,
  fbp: string
) => Promise<CartResponse> = (
  token: string,
  planId: string,
  existingMembershipId: string | undefined,
  googleClientId: string
) => {
  const payload: GetCartRequest = {
    existingMembershipId:
      typeof existingMembershipId === "undefined" ? null : existingMembershipId,
    googleClientId: googleClientId,
    fbp: GetFBPcookie(),
  };
  return fetch(`${baseUrl}/memberships/cart/${planId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const useCart = (
  token: string | undefined,
  planId: string,
  existingMembershipId: string | undefined,
  googleClientId: string
) => {
  return useQuery<CartResponse>(
    [`cart`, `token`, token, planId],
    () => {
      return findCart(
        token!,
        planId,
        existingMembershipId,
        googleClientId,
        GetFBPcookie()
      );
    },
    { refetchOnWindowFocus: false }
  );
};

type CompleteOrderRequest = {
  nonce: string | null;
  captchaToken: string;
  fbp: string;
};

export const completeOrder: (
  cartId: number,
  token: string | undefined,
  nonce: string | null,
  captchaToken: string,
  fbp: string
) => Promise<CartResponse> = (
  cartId: number,
  token: string | undefined,
  nonce: string | null,
  captchaToken: string,
  fbp: string
) => {
  const payload: CompleteOrderRequest = {
    nonce: nonce,
    captchaToken: captchaToken,
    fbp: fbp,
  };

  let fetchUrl: string;
  if (typeof token === "undefined") {
    fetchUrl = `${baseUrl}/memberships/order/${cartId}/anon`;
  } else {
    fetchUrl = `${baseUrl}/memberships/order/${cartId}`;
  }

  return fetch(fetchUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export type PlansResponse = {
  existingMembershipId: string;
  plans: Array<Plan>;
};

export type Plan = {
  id: string;
  name: string;
  trialPeriodDays: number;
  cost: number;
  durationString: string;
  idx: number;
  billAsString: string;
  membershipCostString: string;
  tag: string;
  offerId: string | null;
  planType: string;
  planName: string;
  backendCode: string;
};

export enum PlanTypes {
  AnonymousGO,
  AuthenticatedGO,
  AuthenticatedLB,
  PostCheckoutLB,
  Gift,
}

export const getPlans: (
  token: string | undefined,
  planType: PlanTypes,
  data?: string
) => Promise<PlansResponse> = (
  token: string | undefined,
  planType: PlanTypes,
  data?: string
) => {
  let endpointUrl: string = "";
  switch (planType) {
    case PlanTypes.AnonymousGO:
      endpointUrl = "/memberships/public_plans/GO";
      break;
    case PlanTypes.AuthenticatedGO:
      endpointUrl = "/memberships/plans/GO";
      break;
    case PlanTypes.AuthenticatedLB:
      endpointUrl = "/memberships/plans/LB";
      break;
    case PlanTypes.PostCheckoutLB:
      endpointUrl = "/memberships/post_checkout_plans";
      break;
  }

  let url = `${baseUrl}${endpointUrl}`;
  if (data && data !== "") {
    url = `${url}?data=${data}`;
  }

  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const useMembershipPlans = (
  token: string | undefined,
  planType: PlanTypes,
  data?: string
) => {
  return useQuery<PlansResponse>(
    ["plans", planType, data],
    () => {
      return getPlans(token, planType, data);
    },
    { refetchOnWindowFocus: false }
  );
};

export const useMembershipPlan = (
  token: string | undefined,
  planType: PlanTypes,
  planCode: string | null
) => {
  return useQuery(
    ["plan", planType, planCode],
    () => {
      return getPlans(token, planType);
    },
    { refetchOnWindowFocus: false }
  );
};

export const postPayment = async (
  payment: {
    nonce: string;
    planId: string;
    offerId?: string | null;
    captchaToken: string;
    fbp: string;
  },
  token: string
) => {
  return await fetch(`${baseUrl}/memberships`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify({
      nonce: payment.nonce,
      planId: payment.planId,
      offerId: payment.offerId,
      captchaToken: payment.captchaToken,
      fbp: GetFBPcookie(),
    }),
  });
};

export const updatePaymentMethod = ({
  token,
  nonce,
  membershipId,
}: {
  token: string;
  nonce: string;
  membershipId: string;
}) => {
  return fetch(`${baseUrl}/memberships/payment_method/${membershipId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify({ nonce }),
  });
};

export interface MembershipsResponse {
  memberships: Array<MembershipResponse>;
}
export interface MembershipResponse {
  cardLast4?: string;
  cardExpireMonth?: string;
  cardExpireYear?: string;
  nextBillDate?: string;
  nextBillAmount?: number;
  status: MembershipStatus;
  cardImageUrl?: string;
  payPalAccountEmail?: string;
  deviceId?: string;
  membershipId: string;
  planName: string;
  planId?: string;
  paidThroughDate?: string;
  daysPastDue?: string;
  billingPeriodStartDate?: string;
  billingPeriodEndDate?: string;
  planType: string;
  goDeviceId?: string;
  vrDeviceId?: string;
  pastDueBalance?: string;
  id: string;
  planChangeCredit?: string;
  cost?: string;
  billingIntervalMonths?: number;
  pauseOn?: string;
  pauseUntil?: string;
}

export type MembershipStatus =
  | "TRIAL"
  | "ACTIVE"
  | "CANCELLED"
  | "EXPIRED"
  | "PAUSED"
  | "PAST_DUE";

export const findSubscriptions = (token: string) => {
  return fetch(`${baseUrl}/memberships`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    referrerPolicy: "no-referrer",
  });
};

export const findSubscription = (token: string, membershipId: string) => {
  return fetch(`${baseUrl}/memberships/${membershipId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    referrerPolicy: "no-referrer",
  });
};

export const findTransactions = (token: string) => {
  return fetch(`${baseUrl}/transactions`, {
    method: "GET",
    headers: {
      "Content-Type": "text/html",
      Authorization: `Bearer ${token}`,
    },
    referrerPolicy: "no-referrer",
  });
};

export const usePrefetchMembership = () => {
  const queryClient = useQueryClient();
  return (token: string) => {
    return queryClient.prefetchQuery(["membership"], () => {
      return findSubscriptions(token).then((response) => {
        if (response.ok) {
          return response.json().then((data) => {
            queryClient.setQueryData(
              ["membership", "id", data.membershipId],
              data
            );
            return data;
          });
        } else if (response.status === 404) {
          return undefined;
        } else {
          throw new Error();
        }
      });
    });
  };
};

export const useMemberships = () => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  return useQuery<MembershipsResponse>(
    ["memberships"],
    () => {
      return findSubscriptions(auth.token ?? "").then((response) => {
        if (response.ok) {
          return response.json().then((data) => {
            queryClient.setQueryData(["memberships", "id", auth.token], data);
            return data;
          });
        } else if (response.status === 404) {
          return undefined;
        } else {
          throw new Error("could not fetch memberships");
        }
      });
    },
    {
      enabled: auth.token !== undefined,
    }
  );
};

export const useTransactionsHtml = () => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  return useQuery<string | undefined>(
    ["transactions"],
    () => {
      return findTransactions(auth.token ?? "").then((response) => {
        if (response.ok) {
          return response.text().then((html) => {
            queryClient.setQueryData(["transactions", "id", auth.token], html);
            return html;
          });
        } else {
          throw new Error("could not fetch membership");
        }
      });
    },
    {
      enabled: auth.token !== undefined,
    }
  );
};

export const useMembership = (membershipId: string) => {
  const auth = useAuth();
  return useQuery<MembershipResponse>(
    ["membership", "id", membershipId],
    () => {
      return findSubscription(auth.token ?? "", membershipId).then(
        (response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 404) {
            return undefined;
          } else {
            throw new Error("could not fetch membership");
          }
        }
      );
    },
    {
      enabled: auth.token !== undefined,
    }
  );
};

export const cancelMembership = (
  membershipId: string,
  token: string,
  reason: string,
  reasonDetail: string,
  additionalReason?: string
) => {
  return fetch(`${baseUrl}/memberships/${membershipId}/cancel`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify({ reason, reasonDetail, additionalReason }),
  });
};
export const pauseMembership = (
  membershipId: string,
  token: string,
  pauseMonths: string
) => {
  return fetch(`${baseUrl}/memberships/${membershipId}/pause/${pauseMonths}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    referrerPolicy: "no-referrer",
  });
};

export const unpauseMembership = (membershipId: string, token: string) => {
  return fetch(`${baseUrl}/memberships/${membershipId}/unpause`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    referrerPolicy: "no-referrer",
  });
};
