import * as React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ErrorAlert } from "../../shared/ErrorAlert";
import { InputError } from "../../shared/InputError";
import { Input } from "../../shared/Input";
import { CheckoutRequest, StoreAddressAnonymousProps } from "../store/StoreApi";
import cx from "classnames";

type LiteboxerGoCheckoutMiniFormProps = {
  onSuccess: (checkout: CheckoutRequest) => void;
  email?: string;
};

export const LiteboxerGoCheckoutMiniForm = ({
  onSuccess,
  email,
}: LiteboxerGoCheckoutMiniFormProps) => {
  const initialFormValues = {
    email: email || "",
    acceptsMarketing: true,
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, ...restFormState },
    ...rest
  } = useForm<StoreAddressAnonymousProps>({
    defaultValues: { ...initialFormValues },
  });
  const [globalError, setGlobalError] = React.useState<undefined | string>(
    undefined
  );

  // @TODO: Unhack, check for UUID vs other things?
  // const isAnonymous = typeof token === "undefined";
  const isAnonymous = true;

  const onSubmit: SubmitHandler<StoreAddressAnonymousProps> = (formData) => {
    const checkout: CheckoutRequest = {
      email: formData.email,
      acceptsMarketing: formData.acceptsMarketing,
      billTo: null,
      shipTo: null,
    };
    onSuccess(checkout);
  };

  return (
    <FormProvider
      register={register}
      handleSubmit={handleSubmit}
      setValue={setValue}
      formState={{ ...restFormState, errors, isSubmitting }}
      {...rest}
    >
      <div>
        {globalError ? (
          <ErrorAlert
            heading={globalError}
            onClose={() => {
              setGlobalError(undefined);
            }}
          />
        ) : null}
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          className="font-sans text-sm"
        >
          {isAnonymous && (
            <>
              <div className={"mt-3"}>
                <Input
                  isError={!!errors.email}
                  label={"Email"}
                  id={"email"}
                  placeholder={"Email"}
                  className="text-sm"
                  {...register("email", { required: true })}
                />
              </div>
              {errors.email && errors.email.type === "required" && (
                <InputError id="email-error">This field is required</InputError>
              )}
              {errors.email && errors.email.type === "server" && (
                <InputError id="last_name-error">
                  {errors.email.message ?? "Unknown error"}
                </InputError>
              )}
            </>
          )}

          {isAnonymous && (
            <>
              <div className={"mt-3 flex"}>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="marketing"
                      type="checkbox"
                      {...register("acceptsMarketing")}
                      className={cx(
                        "h-4 w-4  border-gray-300 rounded focus:ring-green-500 text-green-600"
                      )}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="marketing"
                      className="font-medium text-gray-700"
                    >
                      I would like to receive email communication about
                      Litesport news and product updates.
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}

          <button
            disabled={isSubmitting}
            className="flex my-7 w-36 font-headline text-xs font-black uppercase text-gray-100 justify-center py-1.5 px-3 border border-transparent rounded-sm shadow-sm bg-lb-green hover:bg-black hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mx-auto"
          >
            {isSubmitting ? (
              <svg
                className="animate-spin h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            ) : (
              <>Next</>
            )}
          </button>
        </form>
      </div>
    </FormProvider>
  );
};
