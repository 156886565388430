import * as React from "react";
import { PageContainer } from "../../shared/PageContainer";
import { Button } from "../../shared/Button";
import * as membershipApi from "./PlanApi";
import { Navigate } from "react-router-dom";
import { useMembership } from "./PlanApi";
import { ErrorAlert } from "../../shared/ErrorAlert";
import { useAuthToken } from "../../shared/AuthContext";
import { useNavigate, useParams } from "react-router";
import { useQueryClient } from "react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { InputError } from "../../shared/InputError";
import cx from "classnames";
import { Title } from "../../shared/Title";
import { ScrollToTop } from "../../shared/ScrollToTop";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";

type FormValues = {
  pauseMonths: number;
};
export const PausePlanPage = () => {
  React.useEffect(() => {
    if (window.analytics) {
      window.analytics.page("Members - Pause", {
        source: "memberPortal",
        fbp: GetFBPcookie(),
      });
    }
  }, []);

  const queryClient = useQueryClient();
  const { membershipId } = useParams();
  const { data, isLoading, isError, isSuccess } = useMembership(
    membershipId || ""
  );
  const token = useAuthToken();
  const navigate = useNavigate();
  const [globalError, setGlobalError] = React.useState<string | undefined>(
    undefined
  );
  const [pauseMonths, setPauseMonths] = React.useState("1");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (formValues) => {
    if (data && data.membershipId) {
      membershipApi
        .pauseMembership(data?.membershipId, token!, pauseMonths)
        .then((response) => {
          if (response.ok) {
            queryClient.setQueryData(["membership"], () => undefined);
            navigate("/my-account");
          } else {
            setGlobalError(
              "Could not process membership pause request. Please contact support@litesport.com"
            );
          }
        });
    } else {
    }
  };

  if (isSuccess && data === undefined) {
    return <Navigate to={"/home"} />;
  } else if (isLoading) {
    return (
      <PageContainer>
        <Title>Pause Membership</Title>
        <div>Loading...</div>
      </PageContainer>
    );
  } else if (isError) {
    return (
      <PageContainer>
        <Title>Pause Membership</Title>
        <ErrorAlert
          heading={
            "Could retrieve membership information. Please contact support@litesport.com"
          }
        />
      </PageContainer>
    );
  } else {
    return (
      <>
        <ScrollToTop />
        <PageContainer>
          <Title>Need a Break?</Title>
          <br />
          <p>
            You may pause your membership for 1, 2, or 3 months, beginning on
            your next bill date. <br />
            <br />
            You will not be billed while your membership is paused, but you will
            also not have access to any Litesport premium content. <br />
            <br />
            When your membership resumes, it will renew at the then-current rate
            for your plan. Any existing promotional pricing or discounts will be
            lost.
            <br />
            <br />
            You may return here to resume your membership at any time.
            <br />
            <br />
          </p>
          {globalError ? (
            <ErrorAlert
              heading={globalError}
              onClose={() => {
                setGlobalError(undefined);
              }}
            />
          ) : null}
          <form onSubmit={handleSubmit(onSubmit)}>
            <select
              className={cx(
                "mt-1 block w-full pl-3 pr-10 py-2 text-base  focus:outline-none   sm:text-sm rounded-md",
                {
                  "border-gray-300 focus:ring-green-500 focus:border-green-500":
                    !errors.pauseMonths,
                },
                {
                  "border-red-300 focus:ring-red-500 focus:border-red-500":
                    !!errors.pauseMonths,
                }
              )}
              defaultValue="1"
              {...register("pauseMonths", {
                required: true,
                onChange: (e) => {
                  setPauseMonths(e.target.value);
                },
              })}
            >
              <option label="1 Month" value="1"></option>
              <option label="2 Months" value="2"></option>
              <option label="3 Months" value="3"></option>
            </select>
            {errors.pauseMonths && errors.pauseMonths.type === "required" && (
              <InputError id="pauseMonths-error">
                This field is required
              </InputError>
            )}

            <>
              <Button type={"submit"} color={"red"} className={"mt-2 mr-2"}>
                Pause Membership
              </Button>
              <Button
                color={"green"}
                onClick={() => {
                  navigate("/my-account");
                }}
              >
                Keep Punching
              </Button>
            </>
          </form>
        </PageContainer>
      </>
    );
  }
};
