import * as React from "react";
import { Input } from "../../shared/Input";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorAlert } from "../../shared/ErrorAlert";
import { InputError } from "../../shared/InputError";
import { LiteboxerButton } from "../../shared/LiteboxerButton";
import cx from "classnames";
import * as authApi from "./AuthApi";
import { SignupSuccess } from "./AuthApi";
import { useAuth } from "../../shared/AuthContext";
import { ErrorDetails } from "../../shared/Http";
import { useQueryClient } from "react-query";
import * as profileApi from "../profile/ProfileApi";
import { useState } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";

type SignupFormProps = {
  email: string;
  firstName: string;
  lastName: string;
  displayName: string;
  agreeToTerms: boolean;
  acceptsMarketing: boolean;
};

type SignupWidgetProps = {
  onSuccess: (accessToken: string) => void;
  email?: string;
  firstName?: string;
  lastName?: string;
  canChangeEmail?: boolean;
};

export const SignupWidget = ({
  onSuccess,
  email,
  firstName,
  lastName,
  canChangeEmail = true,
}: SignupWidgetProps) => {
  const [session, setSession] = useState<
    { email: string; session: string } | undefined
  >(undefined);
  if (session === undefined) {
    return (
      <SignupForm
        onSuccess={(email, newSession) => {
          setSession({ email, session: newSession });
        }}
        email={email}
        firstName={firstName}
        lastName={lastName}
        canChangeEmail={canChangeEmail}
      />
    );
  } else {
    return (
      <EnterCodeForm
        email={session.email}
        session={session.session}
        onSuccess={(accessToken) => {
          onSuccess(accessToken);
        }}
      />
    );
  }
};

export const SignupForm = ({
  onSuccess,
  email,
  firstName,
  lastName,
  canChangeEmail = true,
}: {
  onSuccess: (email: string, session: string) => void;
  email?: string;
  firstName?: string;
  lastName?: string;
  canChangeEmail?: boolean;
}) => {
  const [globalError, setGlobalError] = React.useState<undefined | string>(
    undefined
  );

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<SignupFormProps>({
    defaultValues: {
      email: email ? email : "",
      firstName: firstName ? firstName : "",
      lastName: lastName ? lastName : "",
      displayName: "",
      agreeToTerms: false,
      acceptsMarketing: true,
    },
  });
  const onSubmit: SubmitHandler<SignupFormProps> = (data) => {
    return authApi
      .signupWithEmail(
        data.email,
        data.firstName,
        data.lastName,
        data.displayName,
        data.acceptsMarketing,
        GetFBPcookie()
      )
      .then((response) => {
        if (response.ok) {
          response.json().then((success: SignupSuccess) => {
            if (window.analytics) {
              window.analytics.identify(success.uuid, {
                firstName: success.firstName,
                lastName: success.lastName,
                displayName: success.displayName,
                email: data.email,
                source: "memberPortal",
                acceptsMarketing: data.acceptsMarketing,
                fbp: GetFBPcookie(),
              });
            }
            onSuccess(data.email, success.session);
          });
        } else if (response.status === 400) {
          response
            .json()
            .then((errorDetails: ErrorDetails<SignupFormProps>) => {
              if (errorDetails.error.displayName) {
                setError("displayName", {
                  type: "server",
                  message: errorDetails.error.displayName,
                });
              } else {
                setGlobalError(errorDetails.status);
              }
            });
        } else {
          setGlobalError(
            "Could not create a new account. Please contact support@litesport.com"
          );
        }
      });
  };
  return (
    <div>
      {globalError ? (
        <ErrorAlert
          heading={globalError}
          onClose={() => {
            setGlobalError(undefined);
          }}
        />
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"mt-3"}>
          <Input
            label={"Email"}
            id={"email"}
            isError={!!errors.email}
            placeholder={"Email Address"}
            disabled={!canChangeEmail}
            {...register("email", { required: true })}
          />
        </div>
        {errors.email && errors.email.type === "required" && (
          <InputError id="email-error">This field is required</InputError>
        )}
        {errors.email && errors.email.type === "server" && (
          <InputError id="email-error">
            {errors.email.message ?? "Unknown error"}
          </InputError>
        )}
        <div className={"mt-3"}>
          <Input
            isError={!!errors.firstName}
            label={"First Name"}
            id={"firstName"}
            placeholder={"First Name"}
            {...register("firstName", { required: true })}
          />
        </div>
        {errors.firstName && errors.firstName.type === "required" && (
          <InputError id="firstName-error">This field is required</InputError>
        )}
        {errors.firstName && errors.firstName.type === "server" && (
          <InputError id="firstName-error">
            {errors.firstName.message ?? "Unknown error"}
          </InputError>
        )}
        <div className={"mt-3"}>
          <Input
            isError={!!errors.lastName}
            label={"Last Name"}
            id={"lastName"}
            placeholder={"Last Name"}
            {...register("lastName", { required: true })}
          />
        </div>
        {errors.lastName && errors.lastName.type === "required" && (
          <InputError id="lastName-error">This field is required</InputError>
        )}
        {errors.lastName && errors.lastName.type === "server" && (
          <InputError id="lastName-error">
            {errors.lastName.message ?? "Unknown error"}
          </InputError>
        )}
        <div className={"mt-3"}>
          <Input
            label={"Username"}
            isError={!!errors.displayName}
            id={"username"}
            placeholder={"Username"}
            {...register("displayName", { required: true })}
          />
        </div>
        {!errors.displayName ? (
          <p className="mt-2 text-sm text-gray-500" id="email-description">
            Your username will be visible to other members in the Litesport
            community. This can be edited later.
          </p>
        ) : null}
        {errors.displayName && errors.displayName.type === "required" && (
          <InputError id="lastName-error">This field is required</InputError>
        )}
        {errors.displayName && errors.displayName.type === "server" && (
          <InputError id="username-error">
            {errors.displayName.message ?? "Unknown error"}
          </InputError>
        )}

        <div className={"mt-3"}>
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id="terms"
                type="checkbox"
                {...register("agreeToTerms", { required: true })}
                className={cx("h-4 w-4  border-gray-300 rounded", {
                  "focus:ring-green-500 text-green-600": !errors.agreeToTerms,
                  "focus:ring-red-500 text-red-600": errors.agreeToTerms,
                })}
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="terms" className="font-medium text-gray-700">
                I agree with the{" "}
                <a
                  href={"https://litesport.com/terms"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"underline"}
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href={"https://litesport.com/privacy"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={"underline"}
                >
                  Privacy Policy
                </a>
              </label>
            </div>
          </div>
          <div>
            {errors.agreeToTerms && errors.agreeToTerms.type === "required" && (
              <InputError id="agreeToTerms-error">
                You must agree with Terms of Service and Privacy Policy to
                continue.
              </InputError>
            )}
          </div>
        </div>
        <div className={"mt-3"}>
          <div className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id="marketing"
                type="checkbox"
                {...register("acceptsMarketing")}
                className={cx(
                  "h-4 w-4  border-gray-300 rounded focus:ring-green-500 text-green-600"
                )}
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="marketing" className="font-medium text-gray-700">
                I would like to receive email communication about Litesport news
                and product updates.
              </label>
            </div>
          </div>
        </div>
        <LiteboxerButton
          text={"CREATE ACCOUNT"}
          className={"mt-8"}
          isLoading={isSubmitting}
        />
      </form>
    </div>
  );
};

const EnterCodeForm = ({
  onSuccess,
  email,
  session,
}: {
  onSuccess: (accessToken: string) => void;
  email: string;
  session: string;
}) => {
  const [globalError, setGlobalError] = React.useState<undefined | string>(
    undefined
  );
  const auth = useAuth();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<{ code: string }>({
    defaultValues: {
      code: "",
    },
  });

  const onSubmit: SubmitHandler<{ code: string }> = (data) => {
    setGlobalError(undefined);
    return authApi.verifyEmail(session, data.code, email).then((response) => {
      if (response.ok) {
        return response.json().then((success: authApi.AuthSuccess) => {
          if (window.analytics) {
            window.analytics.identify(success.uuid, {
              firstName: success.firstName,
              lastName: success.lastName,
              displayName: success.displayName,
              email: email,
              source: "memberPortal",
              fbp: GetFBPcookie(),
            });
          }
          auth.setToken(success.accessToken);
          queryClient.prefetchQuery(["profile"], () => {
            return profileApi.getProfile(success.accessToken);
          });
          if (onSuccess) {
            onSuccess(success.accessToken);
          }
        });
      } else if (response.status === 400) {
        response.json().then((errorDetails: ErrorDetails) => {
          if (errorDetails.error.code) {
            if (errorDetails.error.code) {
              setError("code", {
                type: "server",
                message: errorDetails.error.code,
              });
            }
          } else {
            setGlobalError(errorDetails.error.error);
          }
        });
      } else {
        setGlobalError("Could not login. Please contact support@litesport.com");
      }
    });
  };
  return (
    <div>
      {globalError ? (
        <ErrorAlert
          heading={globalError}
          onClose={() => {
            setGlobalError(undefined);
          }}
        />
      ) : null}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"mt-3"}>
          <label
            htmlFor="code"
            className="block text-sm font-medium text-gray-700 sr-only"
          >
            Code
          </label>
          <div className="mt-1 relative">
            <input
              {...register("code", { required: true })}
              type="text"
              name="code"
              id="code"
              className={cx(
                {
                  "focus:ring-green-500 focus:border-green-500": !errors.code,
                  "border-red-300 text-red-900 focus:outline-none focus:ring-red-500 focus:border-red-500":
                    errors.code,
                },
                "shadow-sm  block w-full sm:text-sm border-gray-300 rounded-sm"
              )}
              placeholder="Code"
              aria-describedby="code-description"
            />
            {errors.code && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {errors.code && errors.code.type === "required" && (
            <InputError id="code-error">This field is required</InputError>
          )}
          {errors.code && errors.code.type === "server" && (
            <InputError id="code-error">
              {errors.code.message ?? "Unknown error"}
            </InputError>
          )}
        </div>

        <LiteboxerButton
          type={"submit"}
          text={"Login"}
          className={"mt-8"}
          isLoading={isSubmitting}
        />
      </form>
    </div>
  );
};
