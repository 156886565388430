import * as React from "react";
import { Cart, CartProduct, getCurrencyFormatter } from "../store/StoreApi";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/outline";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";
import nl2br from "react-nl2br";

export type LiteboxerGoCartPlanProps = {
  planType: string;
  planName: string;
};

type LiteboxerGoCartProps = {
  cart: Cart;
  orderPlaced?: boolean;
};

export const LiteboxerGoCart = ({
  cart,
  orderPlaced = false,
}: LiteboxerGoCartProps) => {
  const [cartShown, setCartShown] = React.useState(true);

  const showCart = (i: boolean) => {
    if (cartShown) {
      if (window.analytics) {
        window.analytics.track("Cart Viewed", { fbp: GetFBPcookie() });
      }
    }
    setCartShown(i);
  };
  const formatter = getCurrencyFormatter();

  const iconClasses = "w-5 h-4.5 mx-1.5 inline";

  type CartItemProps = { item: CartProduct };
  const CartItem = ({ item }: CartItemProps) => {
    let price = item.price;

    return (
      <div className="flex mt-8">
        <div className="flex-initial w-20 px-3 align-top">
          <img
            src={process.env.PUBLIC_URL + item.image}
            alt="Liteboxer Go Membership"
          />
        </div>
        <div className="flex-1">
          <h2 className="font-sans text-sm font-bold uppercase">{item.name}</h2>
          <div className="text-sm">{nl2br(item.desc)}</div>
        </div>
        <div className="flex-initial px-3 font-sans text-sm text-right">
          {item.comparePrice && (
            <>
              <span className="line-through">
                {formatter.format(item.comparePrice)}
              </span>
              <br />
              &nbsp;
            </>
          )}
          {price === 0 ? "FREE" : formatter.format(price)}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white border-black border font-sans">
      <div
        className="font-sans text-black text-sm py-2 text-center"
        onClick={() => {
          showCart(!cartShown);
        }}
      >
        {(cartShown && (
          <ChevronUpIcon className={iconClasses} aria-hidden="true" />
        )) || <ChevronDownIcon className={iconClasses} aria-hidden="true" />}
        {orderPlaced ? "View Order Details" : "View Cart"}
      </div>
      {cartShown && (
        <div className="mb-4">
          {cart.items.map((i) => (
            <React.Fragment key={i.sku}>
              <CartItem item={i} />
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
