import * as React from "react";
import { PaymentCardInput } from "../payments/PaymentCardInput";
import * as dropin from "braintree-web-drop-in";
import { usePaymentToken } from "../payments/PaymentApi";
import { format } from "date-fns";
// import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
// import useOnclickOutside from "react-cool-onclickoutside";
import { getCaptchaToken, useCaptcha } from "../analytics/Recaptcha";
import {
  Cart,
  // CheckoutRequest,
  Order,
  CheckoutState,
  CartProduct,
  getCurrencyFormatter,
  PlanChangeOrderRequest,
  completePlanChangeOrder,
  // StoreAddressForm,
} from "../store/StoreApi";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthToken } from "../../shared/AuthContext";
import nl2br from "react-nl2br";

import { trackOrderCompleted } from "../analytics/AnalyticsApi";
// import {
//   FormProvider,
//   SubmitHandler,
//   useForm,
//   useFormContext,
// } from "react-hook-form";
// import { InputError } from "../../shared/InputError";
// import { Input } from "../../shared/Input";
// import { Select } from "../../shared/Select";
// import { useProfile } from "../profile/UseProfile";
// import cx from "classnames";
import { Button } from "../../shared/Button";
import { ErrorAlert } from "../../shared/ErrorAlert";
import Logo from "../../shared/Logo";

const headingClasses = "uppercase font-sans text-xs font-bold tracking-wider";

type PlanChangeCartProps = {
  cart: Cart;
  orderPlaced?: boolean;
};

export const PlanChangeCart = ({ cart }: PlanChangeCartProps) => {
  const formatter = getCurrencyFormatter();

  type CartItemProps = { item: CartProduct };
  const CartItem = ({ item }: CartItemProps) => {
    let price = item.price;

    return (
      <div className="flex mt-8">
        <div className="flex-initial w-20 px-3 align-top">
          <img
            src={process.env.PUBLIC_URL + item.image}
            alt="Litesport Membership"
          />
        </div>
        <div className="flex-1">
          <h2 className="font-sans text-sm font-bold uppercase">
            Change Plan to {item.name}
          </h2>
          <div className="text-sm">{nl2br(item.desc)}</div>
        </div>
        <div className="flex-initial px-3 font-sans text-sm text-right">
          {item.planChangeCredit && item.planChangeCredit > 0 && (
            <>Credit applied from existing plan: {item.planChangeCredit}</>
          )}
          {price === 0 ? (
            "NOTHING DUE TODAY"
          ) : (
            <>DUE TODAY: {formatter.format(price)}</>
          )}
          <p></p>
          {item.planChangeNextBillDate && (
            <>
              NEXT BILL DATE:{" "}
              {item.planChangeNextBillDate
                ? format(new Date(item.planChangeNextBillDate), "MM/dd/y")
                : ""}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <figure className={headingClasses}>Review Change Details</figure>
      <div className="bg-white border-black border font-sans mt-4">
        <div className="mb-4">
          {cart.items.map((i) => (
            <React.Fragment key={i.sku}>
              <CartItem item={i} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

// type PlanChangeAddressFormProps = {
//   //onSuccess: (checkout: CheckoutRequest) => void;
//   firstName?: string;
//   lastName?: string;
//   email?: string;
//   acceptsMarketing?: boolean;
// };

// export const PlanChangeAddressForm = ({
//   //onSuccess,
//   firstName,
//   lastName,
//   email,
//   acceptsMarketing,
// }: PlanChangeAddressFormProps) => {
//   const { data: profile } = useProfile();

//   const initialFormValues = {
//     first_name: firstName || (profile && profile.firstName) || "",
//     last_name: lastName || (profile && profile.lastName) || "",
//     company: "",
//     address1: "",
//     address2: "",
//     city: "",
//     country: "United States",
//     province: "",
//     zip: "",
//     phone: "",
//     email: email || "",
//     acceptsMarketing:
//       typeof acceptsMarketing === "undefined" ? true : acceptsMarketing,
//   };
//   const {
//     register,
//     handleSubmit,
//     setValue,
//     formState: { errors, isSubmitting, ...restFormState },
//     ...rest
//   } = useForm<StoreAddressForm>({
//     defaultValues: { ...initialFormValues },
//   });
//   const [globalError, setGlobalError] = React.useState<undefined | string>(
//     undefined
//   );

//   const isAnonymous = false;

//   const onSubmit: SubmitHandler<StoreAddressForm> = (formData) => {
//     const checkout: CheckoutRequest = {
//       email: formData.email,
//       acceptsMarketing: formData.acceptsMarketing,
//       billTo: null,
//       shipTo: { ...formData },
//     };
//     //onSuccess(checkout);
//   };

//   const processPhone = (v: string) => {
//     const value = v.replace(/[^0-9]+/g, "");
//     if (value.length === 11 && value[0] === "1") {
//       return value.slice(1);
//     } else {
//       return value;
//     }
//   };

//   const PlacesAutocomplete = () => {
//     const {
//       setValue,
//       register,
//       formState: { errors },
//     } = useFormContext();
//     const {
//       setValue: setAutocompleteValue,
//       suggestions: { status, data },
//       clearSuggestions,
//     } = usePlacesAutocomplete({
//       requestOptions: {
//         /* Define search scope here */
//       },
//       debounce: 300,
//     });
//     const ref = useOnclickOutside(() => {
//       // When user clicks outside of the component, we can dismiss
//       // the searched suggestions by calling this method
//       clearSuggestions();
//     });

//     const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
//       // Update the keyword of the input element
//       setAutocompleteValue(e.target.value);
//     };

//     const handleSelect =
//       (prediction: google.maps.places.AutocompletePrediction) => () => {
//         // When user selects a place, we can replace the keyword without request data from API
//         // by setting the second parameter to "false"
//         // setValue(prediction.description, false);
//         clearSuggestions();

//         // Get latitude and longitude via utility functions
//         getGeocode({ address: prediction.description }).then((results) => {
//           if (results.length > 0) {
//             const result = results[0];
//             const findShortAddressPart = (s: string) =>
//               result.address_components.find((i) => i.types.includes(s))
//                 ?.short_name || "";
//             const findLongAddressPart = (s: string) =>
//               result.address_components.find((i) => i.types.includes(s))
//                 ?.long_name || "";
//             setValue(
//               "address1",
//               `${findShortAddressPart("street_number")} ${findShortAddressPart(
//                 "route"
//               )}`
//             );
//             setValue("city", findShortAddressPart("locality"));
//             setValue(
//               "province",
//               findLongAddressPart("administrative_area_level_1")
//             );
//             setValue(
//               "zip",
//               findShortAddressPart("postal_code_suffix") !== ""
//                 ? `${findShortAddressPart(
//                     "postal_code"
//                   )}-${findShortAddressPart("postal_code_suffix")}`
//                 : findShortAddressPart("postal_code")
//             );
//           }
//         });
//       };

//     const renderSuggestions = () =>
//       data.map((suggestion) => {
//         const {
//           place_id,
//           structured_formatting: { main_text, secondary_text },
//         } = suggestion;

//         return (
//           <li
//             key={place_id}
//             className="cursor-pointer font-sans text-sm m-3"
//             onClick={handleSelect(suggestion)}
//           >
//             <strong>{main_text}</strong> {secondary_text}
//           </li>
//         );
//       });

//     return (
//       <div ref={ref}>
//         <div className={"mt-3"}>
//           <Input
//             isError={!!errors.address1}
//             label={"Address"}
//             id={"address1"}
//             placeholder={"Address"}
//             className="text-sm"
//             {...register("address1", { required: true, onChange: handleInput })}
//           />
//         </div>
//         {errors.address1 && errors.address1.type === "required" && (
//           <InputError id="address1-error">This field is required</InputError>
//         )}
//         {errors.address1 && errors.address1.type === "server" && (
//           <InputError id="address1-error">
//             {errors.address1.message ?? "Unknown error"}
//           </InputError>
//         )}
//         {status === "OK" && <ul>{renderSuggestions()}</ul>}
//       </div>
//     );
//   };

//   const processPostalCode = (v: string) => v.replace(/[^0-9-]+/g, "");
//   return (
//     <FormProvider
//       register={register}
//       handleSubmit={handleSubmit}
//       setValue={setValue}
//       formState={{ ...restFormState, errors, isSubmitting }}
//       {...rest}
//     >
//       <div>
//         {globalError ? (
//           <ErrorAlert
//             heading={globalError}
//             onClose={() => {
//               setGlobalError(undefined);
//             }}
//           />
//         ) : null}
//         <form
//           onSubmit={handleSubmit(onSubmit)}
//           autoComplete="off"
//           className="font-sans text-sm"
//         >
//           <figure className={headingClasses}>Shipping Details</figure>
//           <div className="flex">
//             <div className={"flex-1"}>
//               <div className={"mt-3 mr-2 "}>
//                 <Input
//                   isError={!!errors.first_name}
//                   label={"First Name"}
//                   id={"first_name"}
//                   placeholder={"First Name"}
//                   className="text-sm"
//                   {...register("first_name", { required: true })}
//                 />
//               </div>

//               {errors.first_name && errors.first_name.type === "required" && (
//                 <InputError id="first_name-error">
//                   This field is required
//                 </InputError>
//               )}
//               {errors.first_name && errors.first_name.type === "server" && (
//                 <InputError id="first_name-error">
//                   {errors.first_name.message ?? "Unknown error"}
//                 </InputError>
//               )}
//             </div>

//             <div className={"mt-3 flex-1"}>
//               <Input
//                 isError={!!errors.last_name}
//                 label={"Last Name"}
//                 id={"last_name"}
//                 placeholder={"Last Name"}
//                 className="text-sm"
//                 {...register("last_name", { required: true })}
//               />

//               {errors.last_name && errors.last_name.type === "required" && (
//                 <InputError id="last_name-error">
//                   This field is required
//                 </InputError>
//               )}
//               {errors.last_name && errors.last_name.type === "server" && (
//                 <InputError id="last_name-error">
//                   {errors.last_name.message ?? "Unknown error"}
//                 </InputError>
//               )}
//             </div>
//           </div>

//           {isAnonymous && (
//             <>
//               <div className={"mt-3"}>
//                 <Input
//                   isError={!!errors.email}
//                   label={"Email"}
//                   id={"email"}
//                   placeholder={"Email"}
//                   className="text-sm"
//                   {...register("email", { required: true })}
//                 />
//               </div>
//               {errors.email && errors.email.type === "required" && (
//                 <InputError id="email-error">This field is required</InputError>
//               )}
//               {errors.email && errors.email.type === "server" && (
//                 <InputError id="last_name-error">
//                   {errors.email.message ?? "Unknown error"}
//                 </InputError>
//               )}
//             </>
//           )}

//           <PlacesAutocomplete />

//           <div className={"mt-3"}>
//             <Input
//               isError={!!errors.address2}
//               label={"Address 2"}
//               id={"address2"}
//               placeholder={"Apartment, Suite, etc. (Optional)"}
//               className="text-sm"
//               {...register("address2", { required: false })}
//             />
//           </div>
//           {errors.address2 && errors.address2.type === "required" && (
//             <InputError id="address2-error">This field is required</InputError>
//           )}
//           {errors.address2 && errors.address2.type === "server" && (
//             <InputError id="address2-error">
//               {errors.address2.message ?? "Unknown error"}
//             </InputError>
//           )}

//           <div className="flex">
//             <div className={"mt-3 mr-2 flex-1"}>
//               <Input
//                 isError={!!errors.city}
//                 label={"City"}
//                 id={"city"}
//                 placeholder={"City"}
//                 className="text-sm"
//                 {...register("city", { required: true })}
//               />

//               {errors.city && errors.city.type === "required" && (
//                 <InputError id="city-error">This field is required</InputError>
//               )}
//               {errors.city && errors.city.type === "server" && (
//                 <InputError id="city-error">
//                   {errors.city.message ?? "Unknown error"}
//                 </InputError>
//               )}
//             </div>

//             <div className={"mt-3 mr-2 flex-1"}>
//               <Select
//                 isError={!!errors.province}
//                 label={"State"}
//                 id={"province"}
//                 placeholder={"State"}
//                 className="text-sm"
//                 list="us_states"
//                 {...register("province", { required: true })}
//               >
//                 <option value="Alabama">AL</option>
//                 <option value="Arizona">AZ</option>
//                 <option value="Arkansas">AK</option>
//                 <option value="California">CA</option>
//                 <option value="Colorado">CO</option>
//                 <option value="Connecticut">CT</option>
//                 <option value="Delaware">DE</option>
//                 <option value="District of Columbia">DC</option>
//                 <option value="Florida">FL</option>
//                 <option value="Georgia">GA</option>
//                 <option value="Idaho">ID</option>
//                 <option value="Illinois">IL</option>
//                 <option value="Indiana">IN</option>
//                 <option value="Iowa">IA</option>
//                 <option value="Kansas">KS</option>
//                 <option value="Kentucky">KY</option>
//                 <option value="Louisiana">LA</option>
//                 <option value="Maine">ME</option>
//                 <option value="Maryland">MD</option>
//                 <option value="Massachusetts">MA</option>
//                 <option value="Michigan">MI</option>
//                 <option value="Minnesota">MN</option>
//                 <option value="Mississippi">MS</option>
//                 <option value="Missouri">MO</option>
//                 <option value="Montana">MT</option>
//                 <option value="Nebraska">NE</option>
//                 <option value="Nevada">NV</option>
//                 <option value="New Hampshire">NH</option>
//                 <option value="New Jersey">NJ</option>
//                 <option value="New Mexico">NM</option>
//                 <option value="New York">NY</option>
//                 <option value="North Carolina">NC</option>
//                 <option value="North Dakota">ND</option>
//                 <option value="Ohio">OH</option>
//                 <option value="Oklahoma">OK</option>
//                 <option value="Oregon">OR</option>
//                 <option value="Pennsylvania">PA</option>
//                 <option value="Rhode Island">RI</option>
//                 <option value="South Carolina">SC</option>
//                 <option value="South Dakota">SD</option>
//                 <option value="Tennessee">TN</option>
//                 <option value="Texas">TX</option>
//                 <option value="Utah">UT</option>
//                 <option value="Vermont">VT</option>
//                 <option value="Virginia">VA</option>
//                 <option value="Washington">WA</option>
//                 <option value="West Virginia">WV</option>
//                 <option value="Wisconsin">WI</option>
//                 <option value="Wyoming">WY</option>
//               </Select>

//               {errors.province && errors.province.type === "required" && (
//                 <InputError id="province-error">
//                   This field is required
//                 </InputError>
//               )}
//               {errors.province && errors.province.type === "server" && (
//                 <InputError id="province-error">
//                   {errors.province.message ?? "Unknown error"}
//                 </InputError>
//               )}
//             </div>

//             <div className={"mt-3 flex-1"}>
//               <Input
//                 isError={!!errors.zip}
//                 label={"ZIP"}
//                 id={"zip"}
//                 placeholder={"ZIP Code"}
//                 className="text-sm"
//                 {...register("zip", {
//                   required: true,
//                   setValueAs: (v: string) => processPostalCode(v),
//                   validate: {
//                     server: (v: string) =>
//                       processPostalCode(v).match(/^[0-9]{5}(-[0-9]{4})?$/) !==
//                         null || "Please enter a valid US ZIP or ZIP+4 code",
//                   },
//                 })}
//               />
//               {errors.zip && errors.zip.type === "required" && (
//                 <InputError id="zip-error">This field is required</InputError>
//               )}
//               {errors.zip && errors.zip.type === "server" && (
//                 <InputError id="zip-error">
//                   {errors.zip.message ?? "Unknown error"}
//                 </InputError>
//               )}{" "}
//             </div>
//           </div>

//           <div className={"mt-3"}>
//             <Input
//               isError={!!errors.country}
//               label={"Country"}
//               id={"country"}
//               placeholder={"Country"}
//               disabled={true}
//               value={"United States"}
//               hidden={true}
//               className="text-sm"
//               {...register("country", { required: true })}
//             />
//           </div>
//           {errors.country && errors.country.type === "required" && (
//             <InputError id="country-error">This field is required</InputError>
//           )}
//           {errors.country && errors.country.type === "server" && (
//             <InputError id="country-error">
//               {errors.country.message ?? "Unknown error"}
//             </InputError>
//           )}

//           <div className={"mt-3"}>
//             <Input
//               isError={!!errors.phone}
//               label={"Phone"}
//               id={"phone"}
//               placeholder={"Phone Number"}
//               className="text-sm"
//               type={"tel"}
//               {...register("phone", {
//                 required: true,
//                 setValueAs: (v: string) => processPhone(v),
//                 validate: {
//                   server: (v: string) =>
//                     processPhone(v).length === 10 ||
//                     "Please enter a valid US phone number",
//                 },
//               })}
//             />
//           </div>
//           {errors.phone && errors.phone.type === "required" && (
//             <InputError id="phone-error">This field is required</InputError>
//           )}
//           {errors.phone && errors.phone.type === "server" && (
//             <InputError id="phone-error">
//               {errors.phone.message ?? "Unknown error"}
//             </InputError>
//           )}

//           {isAnonymous && (
//             <>
//               <div className={"mt-3 flex"}>
//                 <div className="relative flex items-start">
//                   <div className="flex items-center h-5">
//                     <input
//                       id="marketing"
//                       type="checkbox"
//                       {...register("acceptsMarketing")}
//                       className={cx(
//                         "h-4 w-4  border-gray-300 rounded focus:ring-green-500 text-green-600"
//                       )}
//                     />
//                   </div>
//                   <div className="ml-3 text-sm">
//                     <label
//                       htmlFor="marketing"
//                       className="font-medium text-gray-700"
//                     >
//                       I would like to receive email communication about
//                       Liteboxer news and product updates.
//                     </label>
//                   </div>
//                 </div>
//               </div>
//             </>
//           )}

//           <button
//             disabled={isSubmitting}
//             className="flex my-7 w-36 font-headline text-xs font-black uppercase text-gray-100 justify-center py-1.5 px-3 border border-transparent rounded-sm shadow-sm bg-lb-green hover:bg-black hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mx-auto"
//           >
//             {isSubmitting ? (
//               <svg
//                 className="animate-spin h-6 w-6 text-white"
//                 xmlns="http://www.w3.org/2000/svg"
//                 fill="none"
//                 viewBox="0 0 24 24"
//               >
//                 <circle
//                   className="opacity-25"
//                   cx="12"
//                   cy="12"
//                   r="10"
//                   stroke="currentColor"
//                   strokeWidth="4"
//                 />
//                 <path
//                   className="opacity-75"
//                   fill="currentColor"
//                   d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
//                 />
//               </svg>
//             ) : (
//               <>Next</>
//             )}
//           </button>
//         </form>
//       </div>
//     </FormProvider>
//   );
// };

export const PlanChangeCheckout = () => {
  const location = useLocation();
  let state = location.state as CheckoutState;
  const [cart] = React.useState<Cart>(state.cart);
  const token = useAuthToken() || "";
  const [order] = React.useState<Order | undefined>(undefined);
  const paymentTokenQuery = usePaymentToken();
  const [paymentInstance, setPaymentInstance] = React.useState<any>(undefined);
  const [globalError, setGlobalError] = React.useState<undefined | string>(
    undefined
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const onSuccess = () => navigate("/my-account");
  const { membershipId } = useParams();
  useCaptcha();
  const onPaymentChange = React.useCallback(
    (instance: dropin.Dropin | undefined) => {
      setPaymentInstance(instance);
    },
    []
  );

  const submitHandler = () => {
    paymentInstance?.requestPaymentMethod(function (err: any, payload: any) {
      if (!err) {
        SubmitPlanChangeOrder(payload.nonce);
      }
    });
  };

  const SubmitPlanChangeOrder = (nonce: string | null) => {
    setIsLoading(true);
    getCaptchaToken()
      .then((captchaToken) => {
        const orderRequest: PlanChangeOrderRequest = {
          shipTo: cart.shipTo,
          billTo: null,
          cartId: cart.id,
          nonce: nonce,
          captchaToken: captchaToken,
          deviceData: null,
        };
        completePlanChangeOrder(token!, orderRequest)
          .then((response) => {
            if (response) {
              trackOrderCompleted(cart);
              if (onSuccess) {
                onSuccess();
              }
            } else {
              setGlobalError(
                "Something unexpected went wrong updating your membership. Please contact support@litesport.com"
              );
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch(() => {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    if (window.analytics) {
      window.analytics.page("Members - Plan Change Checkout", {
        source: "memberPortal",
        fbp: GetFBPcookie(),
        cart: cart,
      });
    }
  }, [cart]);

  return (
    <div className="min-h-screen bg-lg-go-light-gray relative">
      <Logo />
      <div className={"px-4 pt-20 sm:px-6 lg:px-8 mx-auto"}>
        {/*@TODO - Finish/validate/fix up address support before adding any changes which support Go bundles */}
        {/* {cart.isShippingRequired && <PlanChangeAddressForm />} */}

        <div className="my-3 mb-6">
          <PlanChangeCart
            cart={cart}
            orderPlaced={typeof order !== "undefined"}
          />
        </div>
        <figure className={headingClasses}>Confirm Payment Method</figure>
        <div className="mt-4">
          <PaymentCardInput
            onPaymentInstanceChange={onPaymentChange}
            braintreeToken={paymentTokenQuery?.data?.clientToken ?? ""}
          />
        </div>
        <div className="border-b border-lb-go-border-gray my-4"></div>
        {globalError && (
          <ErrorAlert
            heading={globalError}
            onClose={() => {
              setGlobalError(undefined);
            }}
          />
        )}
        <div className="flex justify-center mb-2">
          <Button
            color={"green"}
            disabled={isLoading}
            // className="flex my-7 w-36 font-headline text-xs font-black uppercase text-gray-100 justify-center py-1.5 px-3 border border-transparent rounded-sm shadow-sm bg-lb-green hover:bg-black hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mx-auto"
            onClick={() => {
              submitHandler();
            }}
          >
            {isLoading ? (
              <svg
                className="animate-spin h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            ) : (
              <> SUBMIT </>
            )}
          </Button>
        </div>
        <div className="flex justify-center">
          <Button
            color={"red"}
            className={"flex justify-center"}
            onClick={() => navigate(`/membership/${membershipId}/manage`)}
          >
            BACK
          </Button>
        </div>
      </div>
    </div>
  );
};
