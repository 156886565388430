import { useLocation } from "react-router";

export const Footer = () => {
  const location = useLocation();

  if (
    location.pathname.indexOf("/vr") === 0 ||
    location.pathname.indexOf("/lb_go") === 0 ||
    location.pathname === "/gift"
  ) {
    return <></>;
  }

  return (
    <footer className={"mt-16"}>
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <div className="-mx-5 -my-2 flex justify-center space-x-6">
          <a
            target="_blank"
            href="https://www.facebook.com/litesportvr"
            title="LITESPORT on Facebook"
            rel="noopener noreferrer"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="presentation"
              className="h-5 w-5"
              viewBox="0 0 32 32"
            >
              <path
                fill="#444"
                d="M18.56 31.36V17.28h4.48l.64-5.12h-5.12v-3.2c0-1.28.64-2.56 2.56-2.56h2.56V1.28H19.2c-3.84 0-7.04 2.56-7.04 7.04v3.84H7.68v5.12h4.48v14.08h6.4z"
              />
            </svg>
            <span className="sr-only">Facebook</span>
          </a>
          {/* <a
            target="_blank"
            href="https://twitter.com/liteboxer"
            title="LITEBOXER on Twitter"
            rel="noopener noreferrer"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="presentation"
              className="h-6 w-6"
              viewBox="0 0 32 32"
            >
              <path
                fill="#444"
                d="M31.281 6.733q-1.304 1.924-3.13 3.26 0 .13.033.408t.033.408q0 2.543-.75 5.086t-2.282 4.858-3.635 4.108-5.053 2.869-6.341 1.076q-5.282 0-9.65-2.836.913.065 1.5.065 4.401 0 7.857-2.673-2.054-.033-3.668-1.255t-2.266-3.146q.554.13 1.206.13.88 0 1.663-.261-2.184-.456-3.619-2.184t-1.435-3.977v-.065q1.239.652 2.836.717-1.271-.848-2.021-2.233t-.75-2.983q0-1.63.815-3.195 2.38 2.967 5.754 4.678t7.319 1.907q-.228-.815-.228-1.434 0-2.608 1.858-4.45t4.532-1.842q1.304 0 2.51.522t2.054 1.467q2.152-.424 4.01-1.532-.685 2.217-2.771 3.488 1.989-.261 3.619-.978z"
              />
            </svg>
            <span className="sr-only">Twitter</span>
          </a> */}
          <a
            target="_blank"
            href="https://instagram.com/liteboxer"
            title="LITESPORT on Instagram"
            rel="noopener noreferrer"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="presentation"
              className="h-6 w-6"
              viewBox="0 0 32 32"
            >
              <path
                fill="#444"
                d="M16 3.094c4.206 0 4.7.019 6.363.094 1.538.069 2.369.325 2.925.544.738.287 1.262.625 1.813 1.175s.894 1.075 1.175 1.813c.212.556.475 1.387.544 2.925.075 1.662.094 2.156.094 6.363s-.019 4.7-.094 6.363c-.069 1.538-.325 2.369-.544 2.925-.288.738-.625 1.262-1.175 1.813s-1.075.894-1.813 1.175c-.556.212-1.387.475-2.925.544-1.663.075-2.156.094-6.363.094s-4.7-.019-6.363-.094c-1.537-.069-2.369-.325-2.925-.544-.737-.288-1.263-.625-1.813-1.175s-.894-1.075-1.175-1.813c-.212-.556-.475-1.387-.544-2.925-.075-1.663-.094-2.156-.094-6.363s.019-4.7.094-6.363c.069-1.537.325-2.369.544-2.925.287-.737.625-1.263 1.175-1.813s1.075-.894 1.813-1.175c.556-.212 1.388-.475 2.925-.544 1.662-.081 2.156-.094 6.363-.094zm0-2.838c-4.275 0-4.813.019-6.494.094-1.675.075-2.819.344-3.819.731-1.037.4-1.913.944-2.788 1.819S1.486 4.656 1.08 5.688c-.387 1-.656 2.144-.731 3.825-.075 1.675-.094 2.213-.094 6.488s.019 4.813.094 6.494c.075 1.675.344 2.819.731 3.825.4 1.038.944 1.913 1.819 2.788s1.756 1.413 2.788 1.819c1 .387 2.144.656 3.825.731s2.213.094 6.494.094 4.813-.019 6.494-.094c1.675-.075 2.819-.344 3.825-.731 1.038-.4 1.913-.944 2.788-1.819s1.413-1.756 1.819-2.788c.387-1 .656-2.144.731-3.825s.094-2.212.094-6.494-.019-4.813-.094-6.494c-.075-1.675-.344-2.819-.731-3.825-.4-1.038-.944-1.913-1.819-2.788s-1.756-1.413-2.788-1.819c-1-.387-2.144-.656-3.825-.731C20.812.275 20.275.256 16 .256z"
              />
              <path
                fill="#444"
                d="M16 7.912a8.088 8.088 0 0 0 0 16.175c4.463 0 8.087-3.625 8.087-8.088s-3.625-8.088-8.088-8.088zm0 13.338a5.25 5.25 0 1 1 0-10.5 5.25 5.25 0 1 1 0 10.5zM26.294 7.594a1.887 1.887 0 1 1-3.774.002 1.887 1.887 0 0 1 3.774-.003z"
              />
            </svg>
            <span className="sr-only">Instagram</span>
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/c/litesportvr"
            title="LITESPORT on YouTube"
            rel="noopener noreferrer"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              role="presentation"
              className="h-6 w-6"
              viewBox="0 0 21 20"
            >
              <path
                fill="#444"
                d="M-.196 15.803q0 1.23.812 2.092t1.977.861h14.946q1.165 0 1.977-.861t.812-2.092V3.909q0-1.23-.82-2.116T17.539.907H2.593q-1.148 0-1.969.886t-.82 2.116v11.894zm7.465-2.149V6.058q0-.115.066-.18.049-.016.082-.016l.082.016 7.153 3.806q.066.066.066.164 0 .066-.066.131l-7.153 3.806q-.033.033-.066.033-.066 0-.098-.033-.066-.066-.066-.131z"
              />
            </svg>
            <span className="sr-only">YouTube</span>
          </a>
        </div>
        <nav className="mt-8 flex flex-wrap justify-center" aria-label="Footer">
          <div className="px-5 py-2">
            <a
              href={"https://support.litesport.com/hc/en-us"}
              className="text-base text-gray-500 hover:text-gray-900"
            >
              Support
            </a>
          </div>
          <div className="px-5 py-2">
            <a
              href={"https://litesport.com/terms"}
              className="text-base text-gray-500 hover:text-gray-900"
            >
              Terms of Service
            </a>
          </div>
          <div className="px-5 py-2">
            <a
              href={"https://litesport.com/privacy"}
              className="text-base text-gray-500 hover:text-gray-900"
            >
              Privacy Policy
            </a>
          </div>
        </nav>
        <p className="mt-8 text-center text-base text-gray-400">
          &copy; 2023 LITESPORT • ALL RIGHTS RESERVED
        </p>
      </div>
    </footer>
  );
};
