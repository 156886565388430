import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import { baseUrl } from "../../shared/Http";
import { useQuery } from "react-query";
import { MembershipResponse, PlanTypes } from "../plan/PlanApi";
import { useParams } from "react-router";

export interface StoreCatalog {
  catalog: Catalog[];
  existingMembership: MembershipResponse;
}

export interface Catalog {
  groupId: number;
  groupName: string;
  products: Product[];
}

export interface Product {
  id?: number;
  sku: string;
  name: string;
  desc: null;
  image: string;
  tag: null | string;
  currency: Currency;
  price: number;
  checkoutPrice: number;
  comparePrice?: number;
  initialPrice?: number;
  initialMonthlyRate?: number;
  initialTermMonths?: number;
  renewalPrice?: number;
  renewalMonthlyRate?: number;
  renewalTermMonths?: number;
  trialDays?: number;
  isShippingRequired: boolean;
  isTokenRequired: boolean;
  activationDelayDays?: number;
  includes?: Product[];
  dueAfterTrial?: number;
  entitlementId?: number;
  entitlementName?: string;
  entitlementCode?: string;
  planChangeNextBillDate?: string;
  planChangeCredit?: number;
  planChangeIsUpgrade?: boolean;
  planChangeIsDowngrade?: boolean;
  planChangeIsCurrentPlan?: boolean;
  planChangeCurrentPlanIsPaused?: boolean;
}

export interface Cart {
  id: number;
  currency: string;
  subtotal: number;
  items: CartProduct[];
  isShippingRequired: boolean;
  isTokenRequired: boolean;
  tax?: number;
  total?: number;
  shipping?: number;
  shipTo: StoreAddress | null;
}

export interface CartProduct {
  productId: number;
  productPricingId: number;
  sku: string;
  name: string;
  desc: null | string;
  image: string;
  tag: null | string;
  currency: string;
  price: number;
  checkoutPrice: number;
  comparePrice: number;
  isShippingRequired: boolean;
  isTokenRequired: boolean;
  initialPrice?: number;
  initialMonthlyRate?: number;
  initialTermMonths?: number;
  renewalPrice?: number;
  renewalMonthlyRate?: number;
  renewalTermMonths?: number;
  trialDays?: number;
  activationDelayDays?: number;
  includes?: CartProduct[];
  qty?: number;
  planChangeNextBillDate?: string;
  planChangeIsUpgrade?: boolean;
  planChangeCredit?: number;
}

export type CheckoutRequest = {
  email: string;
  acceptsMarketing: boolean;
  shipTo: StoreAddress | null;
  billTo: StoreAddress | null;
};

export interface OrderRequest {
  email: string;
  acceptsMarketing: boolean;
  billTo: StoreAddress | null;
  cartId: number;
  nonce: string | null;
  captchaToken: string | null;
  deviceData: any;
  fbp: string;
  isGift: boolean;
  giftMessage: string | null;
  giftFromName: string | null;
  giftToName: string | null;
  giftToEmail: string | null;
  giftNotifyAt: Date | null;
}

export interface PlanChangeOrderRequest {
  billTo: StoreAddress | null;
  shipTo: StoreAddress | null;
  cartId: number;
  nonce: string | null;
  captchaToken: string | null;
  deviceData: any;
}

export type StoreAddress = {
  first_name: string;
  last_name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  country: string;
  province: string;
  province_code?: string;
  zip: string;
  phone: string;
};

export interface Order {
  id: number;
  createdAt: Date;
  currency: string;
  subtotal: number;
  shipping: number;
  tax: number;
  total: number;
  profileId: number;
  isGift: boolean;
  shopifyOrderId: number;
  transactionId: string;
  orderStatusUrl: string;
  entitlementCodes?: string[];
}

type HasSku = {
  sku: string;
};

export type StoreAddressAnonymousProps = {
  email: string;
  acceptsMarketing: boolean;
};

export type StoreAddressForm = StoreAddressAnonymousProps & StoreAddress;

export enum Currency {
  Usd = "USD",
}

export enum CatalogGroups {
  Unknown = -2,
  PostCheckout = -1,
  LB = 1,
  GO = 2,
  VR = 3,
  Gift = 4,
  PlanChange = -3,
}

export type CheckoutState = {
  cart: Cart;
  email?: string;
  createAccount?: boolean;
  acceptsMarketing?: boolean;
  entitlementCode?: string;
  sourceGroup: CatalogGroups;
  data?: string;
  membershipId?: string;
};

export const getCatalog = (
  token: string,
  group?: number,
  data?: string,
  membershipId?: string
): Promise<StoreCatalog> => {
  const options: any = {};
  if (group && group > 0) {
    options["group"] = group;
  }
  if (data) {
    options["data"] = data;
  }
  const paramsString = new URLSearchParams(options).toString();
  var url;
  if (membershipId && membershipId !== "") {
    url = `${baseUrl}/memberships/${membershipId}/options`;
  } else {
    url = `${baseUrl}/store/catalog?${paramsString}`;
  }
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const useCatalog = (token: string, group?: number, data?: string) => {
  const { membershipId } = useParams<"membershipId">();
  return useQuery<StoreCatalog>(
    ["storeCatalog", group, data, membershipId],
    () => {
      return getCatalog(token, group, data, membershipId);
    },
    { refetchOnWindowFocus: false }
  );
};

export const addToCart = (
  token: string,
  cartId: number,
  productId: number,
  entitlementCode?: string,
  membershipId?: string
): Promise<Cart> => {
  var url;
  if (membershipId) {
    url = `${baseUrl}/memberships/${membershipId}/cart/${productId}`;
  } else {
    url = `${baseUrl}/store/cart/${cartId}/item/${productId}`;
  }

  return fetch(url, {
    method: "PUT",
    headers: constructRequestHeaders(token, entitlementCode),
    referrerPolicy: "no-referrer",
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const checkoutByCartId = (
  token: string,
  cartId: number,
  checkout: CheckoutRequest,
  entitlementCode?: string
): Promise<Cart> => {
  return fetch(`${baseUrl}/store/checkout/${cartId}`, {
    method: "POST",
    headers: constructRequestHeaders(token, entitlementCode),
    referrerPolicy: "no-referrer",
    body: JSON.stringify(checkout),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const completeOrder = (
  token: string,
  order: OrderRequest,
  entitlementCode?: string
): Promise<Order> => {
  return fetch(`${baseUrl}/store/order`, {
    method: "POST",
    headers: constructRequestHeaders(token, entitlementCode),
    referrerPolicy: "no-referrer",
    body: JSON.stringify(order),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const completePlanChangeOrder = (
  token: string,
  order: PlanChangeOrderRequest
): Promise<MembershipResponse> => {
  return fetch(`${baseUrl}/memberships/change_order`, {
    method: "POST",
    headers: constructRequestHeaders(token),
    referrerPolicy: "no-referrer",
    body: JSON.stringify(order),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
  });
};

export const getProducts = (c: StoreCatalog | undefined) => {
  if (typeof c === "undefined") {
    return undefined;
  }
  return c.catalog.flatMap((g) => g.products);
};

export const useStoreToken = () => {
  let token = Cookies.get("lb_anonymous_id");
  if (typeof token === "undefined") {
    token = uuidv4();
    Cookies.set("lb_anonymous_id", token);
  }
  return token;
};

export type PaymentTokenResponse = {
  clientToken: string;
};

export const useAnonPaymentToken = () => {
  return useQuery<PaymentTokenResponse>(
    ["braintree-token", "anon"],
    async () => {
      const response = await fetch(`${baseUrl}/store/payment_token`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
      });
      return response.json();
    },
    { refetchOnWindowFocus: false }
  );
};

// @TODO: Parameterize!
export const getCurrencyFormatter = () => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
};

export const isProductGo = (p: HasSku) => {
  return checkProductSku(p, "SI-LBGO");
};

export const isProductMembership = (p: HasSku) => {
  return checkProductSku(p, "SI-MB");
};

const checkProductSku = (p: HasSku, s: string) => {
  return p.sku.startsWith(s);
};

export const findFirstSubscriptionChild = (product: Product) => {
  const subscriptions = product.includes?.filter((i) => i.initialMonthlyRate);
  if (typeof subscriptions !== "undefined") {
    return subscriptions[0];
  } else {
    return undefined;
  }
};

export const getChildProductsFromCart = (cart: Cart) =>
  cart.items.flatMap((i) => (i.includes ? i.includes : i));

export const planTypeToGroup = (p: PlanTypes): CatalogGroups => {
  switch (p) {
    case PlanTypes.AnonymousGO:
    case PlanTypes.AuthenticatedGO:
      return CatalogGroups.GO;
    case PlanTypes.AuthenticatedLB:
      return CatalogGroups.LB;
    case PlanTypes.PostCheckoutLB:
      return CatalogGroups.PostCheckout;
    case PlanTypes.Gift:
      return CatalogGroups.Gift;
    default:
      return CatalogGroups.Unknown;
  }
};

const constructRequestHeaders = (token?: string, entitlementCode?: string) => {
  return Object.assign(
    {},
    { "Content-Type": "application/json" },
    token ? { Authorization: `Bearer ${token}` } : {},
    entitlementCode ? { "lb-entitlement-code": entitlementCode } : {}
  ) as any;
};

export const getEntitlementCodesFromOrder = (order: Order) => {
  if (typeof order.entitlementCodes !== "undefined") {
    return order.entitlementCodes;
  } else {
    return [];
  }
};
