import * as React from "react";
import cx from "classnames";
import {
  useCatalog,
  useStoreToken,
  getProducts,
  addToCart,
  CatalogGroups,
  Cart,
  CheckoutState,
} from "../store/StoreApi";
import { useNavigate } from "react-router-dom";
import styles from "./GiftLandingPage.module.css";

export const GiftLandingPage = () => {
  const navigate = useNavigate();
  const token = useStoreToken();
  const { data } = useCatalog(token, CatalogGroups.Gift);
  const products = getProducts(data);

  const onBuyClick = () => {
    if (typeof products !== "undefined") {
      if (products.length === 1) {
        const product = products[0];

        addToCart(token, 0, product.id!).then((cart: Cart) => {
          // @TODO: Set this in localStorage or similar
          const state: CheckoutState = {
            cart: cart,
            createAccount: false,
            sourceGroup: CatalogGroups.Gift,
          };
          navigate(`/checkout`, {
            state: state,
          });
        });
      }
    }
  };

  return (
    <div className="min-h-screen min-w-md bg-black">
      <div className="mx-auto max-w-2xl relative">
        <picture className="block object-cover w-full">
          <img
            src={process.env.PUBLIC_URL + "/gift_bg.png"}
            alt="Litesport Logo"
          />
        </picture>
        <div
          className={cx("relative max-w-[26rem] mx-auto px-4", styles.textTop)}
        >
          <h1 className="font-headline uppercase text-white font-bold text-center text-3xl leading-5 tracking-wider">
            Give A Year Of
            <br />
            <span
              className={cx(
                "text-[2.825rem] leading-[3rem] font-black tracking-widest",
                styles.fitnessOutline
              )}
            >
              Fitness
            </span>
          </h1>
          <p className="font-sans font-medium text-sm text-center text-white px-6 my-6 tracking-widest">
            A one-year gift subscription to Litesport is the present for
            everyone.
          </p>
          <button
            onClick={onBuyClick}
            className="w-full block py-2 border border-transparent rounded-md shadow-sm bg-lb-green text-white hover:bg-white hover:text-lb-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mx-auto my-6"
          >
            <span className="font-headline font-bold justify-center uppercase text-lg tracking-widest">
              Give A Year
            </span>
          </button>
          <p className="font-sans text-xs leading-5 text-center text-white">
            <strong>May be redeemed by new subscribers only.</strong>
            <br />
            Cannot be added to an existing subscription.
            <br />
            Subscription Cards are only redeemable in the
            <br />
            country in which they are purchased.{" "}
            <a
              href="https://litesport.com/terms"
              className="text-lb-green underline"
            >
              Terms apply.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
