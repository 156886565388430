import * as React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import cx from "classnames";
import styles from "./LiteboxerGoProductPage.module.css";

type ProductPageProps = {
  next: () => void;
};

type CarouselItemProps = {
  hed: string;
  dek: string;
  imageUrl: string;
  wrapperClasses?: string;
  imageClasses?: string;
};

const CarouselItem = ({
  hed,
  dek,
  imageUrl,
  wrapperClasses,
  imageClasses,
}: CarouselItemProps) => {
  return (
    <div className="pb-14">
      <div className={wrapperClasses}>
        <img
          className={imageClasses}
          src={`${process.env.PUBLIC_URL}/${imageUrl}`}
          alt={dek}
        />
      </div>
      <h5 className="font-headline uppercase font-black text-sm tracking-wide px-4 mt-10">
        {hed}
      </h5>
      <aside className="font-headline text-sm px-8 mt-2">{dek}</aside>
    </div>
  );
};

export const LiteboxerGoProductPage = ({ next }: ProductPageProps) => {
  return (
    <>
      <div className="px-6 lg:px-8 bg-white mt-12 pt-8 pb-6">
        <h2 className="font-headline text-4xl uppercase font-black text-center leading-10">
          Ready to Go, Wherever You Go
        </h2>
        <h4 className="font-headline text-sm text-center mt-6 px-4">
          Liteboxer Go is built to be portable and provide on-the-go fitness fun
          no matter your lifestyle. At home, on the road, outdoors or at the
          gym.
        </h4>
      </div>
      <Carousel
        className={cx("bg-white pb-8", styles.carousel)}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
      >
        <CarouselItem
          hed="Punch Tracking Sensors"
          dek="Compact battery-powered sensors connect to the app via Bluetooth technology."
          imageUrl="go-carousel-1-1.jpg"
        />
        <CarouselItem
          hed="Wrist Straps"
          dek="Comfortable enough to wear during the toughest workouts, thanks to durable Neoprene wrist straps."
          imageUrl="go-carousel-1-2.jpg"
        />
        <CarouselItem
          hed="Connect To All Devices"
          dek="Use Liteboxer Go with all devices using Litesport App on mobile, tablet or TV. For an elevated experience, cast the Litesport app from your mobile device to a TV."
          imageUrl="go-carousel-1-3.jpg"
        />
        <CarouselItem
          hed="Track Metrics"
          dek="Built-in accelerometer detects your every punch for best-in-class scoring metrics throughout your workouts so you can track progress over time."
          imageUrl="go-carousel-1-4.jpg"
        />
        <CarouselItem
          hed="Level Up With Accessories"
          dek="Litesport Go ships with all the accessories you need to level up your workout - travel bag, tablet stand and Egg Weights for more intense workouts."
          imageUrl="go-carousel-1-5.jpg"
        />
      </Carousel>
      <div className="px-6 lg:px-8 mt-12 pt-8 pb-6">
        <h2 className="font-headline text-4xl uppercase font-black text-center leading-10">
          Own Your Potential
        </h2>
        <h4 className="font-headline text-sm text-center mt-6 px-2">
          Access unlimited workouts with the Litesport app. New content added
          daily, take your pick from hundreds of workouts ranging in duration,
          music, difficulty and style.*
        </h4>
      </div>
      <Carousel
        className={cx("pb-8", styles.carousel)}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
      >
        <CarouselItem
          hed="Punch Tracks"
          dek="Choose from a library of the latest charting music and punch to the beat."
          imageUrl="go-carousel-2-1.png"
          imageClasses="px-6"
        />
        <CarouselItem
          hed="Trainer Classes"
          dek="Workout alongside best-in-class trainers with classes at every difficulty level."
          imageUrl="go-carousel-2-2.png"
          imageClasses="px-16"
        />
        <CarouselItem
          hed="Track Progress"
          dek="Your performance dashboard tracks your progress over time so you know when you’re getting stronger and fitter."
          imageUrl="go-carousel-2-3.png"
          imageClasses="px-16"
        />
        <CarouselItem
          hed="Competition"
          dek="Compete against yourself and others as you climb the Leaderboard and punch for your personal best score."
          imageUrl="go-carousel-2-4.png"
          imageClasses="px-16 mx-2"
        />
      </Carousel>
      <div className="px-10 lg:px-8 py-6 text-xxs bg-[#d9d9d9]">
        * Must have Litesport Go membership to get access to all Litesport App
        content. Offer valid for new users only.
      </div>
    </>
  );
};
