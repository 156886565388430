import * as React from "react";
import { Menu, Transition } from "@headlessui/react";
import { MenuIcon } from "@heroicons/react/outline";
import cx from "classnames";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as authApi from "../features/auth/AuthApi";
type Props = {
  showMenu: boolean;
};
export const Header = ({ showMenu }: Props) => {
  const navigate = useNavigate();
  return (
    <nav className="bg-gray-50 border-b border-gray-200">
      <>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <img
                  className="block h-8 w-auto"
                  src={
                    process.env.PUBLIC_URL +
                    "/LitesportLogo-Horizontal-Black.png"
                  }
                  alt="Litesport"
                />
              </div>
            </div>
            {showMenu ? (
              <>
                <div className="sm:ml-6 flex items-center">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xs  flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                        <span className="sr-only">Open user menu</span>
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => {
                            return (
                              <Link
                                to={"/my-account"}
                                className={cx(
                                  { "bg-gray-100": active },
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                My Account
                              </Link>
                            );
                          }}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => {
                            return (
                              <a
                                href={"https://support.litesport.com/hc/en-us"}
                                className={cx(
                                  { "bg-gray-100": active },
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Support
                              </a>
                            );
                          }}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => {
                            return (
                              <a
                                href={"https://litesport.com"}
                                className={cx(
                                  { "bg-gray-100": active },
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Learn More
                              </a>
                            );
                          }}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => {
                            return (
                              <a
                                href={"/"}
                                onClick={() => {
                                  authApi.logout().finally(() => {
                                    navigate("/");
                                  });
                                }}
                                className={cx(
                                  { "bg-gray-100": active },
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Logout
                              </a>
                            );
                          }}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </>
    </nav>
  );
};
