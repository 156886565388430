import * as React from "react";
import { useState } from "react";
import { baseUrl } from "./Http";

type AuthContextProps = {
  token: undefined | string;
  setToken: (token: string | undefined) => void;
};

const AuthContext = React.createContext<AuthContextProps>({
  token: undefined,
  setToken: () => {},
});
AuthContext.displayName = "AuthContext";

export const AuthProvider = (props: any) => {
  const [isLoading, setLoading] = useState(true);
  const [token, setToken] = React.useState<undefined | string>(undefined);
  const setAuthToken = React.useCallback(
    (token: string | undefined) => {
      setToken(token);
    },
    [setToken]
  );
  const value = React.useMemo(() => {
    return {
      token,
      setToken: setAuthToken,
    };
  }, [token, setAuthToken]);
  React.useEffect(() => {
    fetch(`${baseUrl}/auth/ping`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (response.ok) {
          return response.json().then((response) => {
            setToken(response.accessToken);
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  if (isLoading) {
    return (
      <div className={"h-screen flex flex-col justify-center"}>
        <div className="flex justify-center items-center ">
          <div className={"p-5 drop-shadow-2xl bg-white rounded-lg pt-2"}>
            <div>
              <img
                style={{
                  width: 128,
                  height: 128,
                }}
                className={`w-12 h-12`}
                src={process.env.PUBLIC_URL + "/LB-logo-black-on-white.png"}
                alt="Litesport Logo"
              />
            </div>

            <div className={"flex align-center justify-center mt-5"}>
              <div
                className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                role="status"
                style={{
                  verticalAlign: "-0.125em",
                  border: "0.25em solid",
                  borderRightColor: "transparent",
                }}
              >
                <span className="hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <AuthContext.Provider value={value} {...props} />;
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export const useAuthToken = () => {
  const { token } = useAuth();
  return token;
};
