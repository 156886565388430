import { useEffect } from "react";

interface OptimizelyProps {
  projectId: string;
}

export const Optimizely = ({ projectId }: OptimizelyProps) => {
  useEffect(() => {
    const i = document.createElement("script");
    i.src = `https://cdn.optimizely.com/js/${projectId}.js`;

    document.head.appendChild(i);
  }, [projectId]);

  return null;
};

export const FireOptimizelyEvent = (eventName: string, tags?: any) => {
  window["optimizely"] = window["optimizely"] || [];
  window["optimizely"].push({
    type: "event",
    eventName: eventName,
    tags: tags,
  });
};
