import * as React from "react";
import { PaymentCardInput } from "../payments/PaymentCardInput";
import {
  useAnonPaymentToken,
  PaymentTokenResponse,
  Cart,
  Order,
  OrderRequest,
  completeOrder,
  getCurrencyFormatter,
} from "../store/StoreApi";
import { getCaptchaToken, useCaptcha } from "../analytics/Recaptcha";
import { ErrorAlert } from "../../shared/ErrorAlert";
import * as dropin from "braintree-web-drop-in";
import { UseQueryResult } from "react-query";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";
import { trackOrderCompleted } from "../analytics/AnalyticsApi";

type LiteboxerGoCheckoutPaymentAnonymousProps = {
  cart: Cart;
  token: string;
  email: string;
  acceptsMarketing: boolean;
  entitlementCode?: string;
  onSuccess?: (order: Order) => void;
};

export const LiteboxerGoCheckoutPaymentAnonymous = ({
  cart,
  token,
  email,
  acceptsMarketing,
  entitlementCode,
  onSuccess,
}: LiteboxerGoCheckoutPaymentAnonymousProps) => {
  const paymentTokenQuery = useAnonPaymentToken();
  return (
    <LiteboxerGoCheckoutPayment
      cart={cart}
      token={token}
      email={email}
      acceptsMarketing={acceptsMarketing}
      entitlementCode={entitlementCode}
      onSuccess={onSuccess}
      paymentTokenQuery={paymentTokenQuery}
    />
  );
};

type GoCheckoutPaymentProps = {
  cart: Cart;
  token: string;
  email: string;
  acceptsMarketing: boolean;
  entitlementCode?: string;
  paymentTokenQuery: UseQueryResult<PaymentTokenResponse, unknown>;
  onSuccess?: (order: Order) => void;
};

const LiteboxerGoCheckoutPayment = ({
  cart,
  token,
  email,
  acceptsMarketing,
  entitlementCode,
  paymentTokenQuery,
  onSuccess,
}: GoCheckoutPaymentProps) => {
  const [paymentInstance, setPaymentInstance] = React.useState<any>(undefined);
  const onPaymentChange = React.useCallback(
    (instance: dropin.Dropin | undefined) => {
      setPaymentInstance(instance);
    },
    []
  );

  const [globalError, setGlobalError] = React.useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [orderSuccessful, setOrderSuccessful] = React.useState(false);
  useCaptcha();
  // const isVirtualOrder = !cart.isShippingRequired;
  const isFreeOrder = !cart.isTokenRequired;

  const options = Object.assign(
    {
      dataCollector: true,
      paypal: {
        flow: "vault",
        currency: "USD",
        amount: cart.total,
      },
      applePay: {
        displayName: "Litesport",
        paymentRequest: {
          total: {
            label: "Litesport",
            amount: cart.total,
          },
          requiredBillingContactFields: ["postalAddress"],
        },
      },
    },
    cart.shipTo &&
      cart.shipTo.zip && {
        card: {
          overrides: {
            fields: {
              postalCode: {
                prefill: cart.shipTo.zip.substring(0, 5),
              },
            },
          },
        },
      }
  );

  const submitHandler = () => {
    if (isFreeOrder) {
      submitOrder(null);
    } else {
      paymentInstance?.requestPaymentMethod(function (err: any, payload: any) {
        if (!err) {
          submitOrder(payload.nonce);
        }
      });
    }
  };

  const submitOrder = (nonce: string | null) => {
    setIsLoading(true);
    getCaptchaToken()
      .then((captchaToken) => {
        const orderRequest: OrderRequest = {
          email: email,
          acceptsMarketing: acceptsMarketing,
          billTo: null,
          cartId: cart.id,
          nonce: nonce,
          captchaToken: captchaToken,
          deviceData: null,
          fbp: GetFBPcookie(),
          isGift: false,
          giftMessage: null,
          giftFromName: null,
          giftToName: null,
          giftToEmail: null,
          giftNotifyAt: null,
        };
        completeOrder(token!, orderRequest, entitlementCode)
          .then((response) => {
            if (response) {
              setOrderSuccessful(true);
              trackOrderCompleted(cart);

              if (onSuccess) {
                onSuccess(response);
              }
            } else {
              setGlobalError(
                "Something unexpected went wrong creating membership. Please contact support@litesport.com"
              );
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch(() => {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formatter = getCurrencyFormatter();

  return (
    <>
      {globalError && (
        <ErrorAlert
          heading={globalError}
          onClose={() => {
            setGlobalError(undefined);
          }}
        />
      )}
      {orderSuccessful ? (
        <></>
      ) : (
        <div className="mt-3">
          {paymentTokenQuery.isLoading ? (
            <div>Loading...</div>
          ) : paymentTokenQuery.data === undefined ? (
            <div>Something went wrong</div>
          ) : (
            !isFreeOrder && (
              <PaymentCardInput
                onPaymentInstanceChange={onPaymentChange}
                braintreeToken={paymentTokenQuery.data.clientToken}
                extraCreateOptions={options}
              />
            )
          )}
          <div className="flex flex-wrap mt-4 font-headline uppercase text-xs text-lb-go-cart-gray">
            <div className="my-0.5 grow text-lb-go-cart-light-gray">
              Subtotal
            </div>
            <div className="my-0.5 flex-initial text-sm">
              {formatter.format(cart.subtotal)}
            </div>
            {/* <div className="basis-full h-0"></div>
            <div className="my-0.5 grow text-lb-go-cart-light-gray">
              Shipping
            </div>
            <div className="my-0.5 flex-initial text-sm">
              {formatter.format(cart.shipping!)}
            </div> */}
            <div className="basis-full h-0"></div>
            <div className="my-0.5 grow text-lb-go-cart-light-gray">Tax</div>
            <div className="my-0.5 flex-initial text-sm">
              {formatter.format(cart.tax!)}
            </div>
            <div className="basis-full h-0"></div>
            <div className="my-0.5 grow text-lb-go-cart-light-gray">
              Total Due
            </div>
            <div className="my-0.5 flex-initial text-sm">
              {formatter.format(cart.total!)}
            </div>
            <div className="basis-full h-0"></div>
          </div>
          <button
            disabled={isLoading}
            className="flex my-7 w-36 font-headline text-xs font-black uppercase text-gray-100 justify-center py-1.5 px-3 border border-transparent rounded-sm shadow-sm bg-lb-green hover:bg-black hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mx-auto"
            onClick={() => {
              submitHandler();
            }}
          >
            {isLoading ? (
              <svg
                className="animate-spin h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            ) : (
              <> Place Order</>
            )}
          </button>
        </div>
      )}
    </>
  );
};
