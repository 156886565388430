import * as React from "react";

export const InputError = ({
  children,
  id,
}: {
  children: React.ReactNode;
  id: string;
}) => {
  return (
    <p className="mt-2 text-sm text-red-600" id={id}>
      {children}
    </p>
  );
};
