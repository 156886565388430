import * as React from "react";
import { PageContainer } from "../../shared/PageContainer";
import { Button } from "../../shared/Button";
import * as membershipApi from "./PlanApi";
import { Navigate } from "react-router-dom";
import { useMembership } from "./PlanApi";
import { ErrorAlert } from "../../shared/ErrorAlert";
import { useAuthToken } from "../../shared/AuthContext";
import { useNavigate, useParams } from "react-router";
import { useQueryClient } from "react-query";
import { SubmitHandler, useForm } from "react-hook-form";
import { InputError } from "../../shared/InputError";
import cx from "classnames";
import { Title } from "../../shared/Title";
import { ScrollToTop } from "../../shared/ScrollToTop";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";

type FormValues = {
  reason: string;
  reasonDetail: string;
  additionalReason?: string;
  months?: string;
};
export const CancelPlanPage = () => {
  React.useEffect(() => {
    if (window.analytics) {
      window.analytics.page("Members - Cancel", {
        source: "memberPortal",
        fbp: GetFBPcookie(),
      });
    }
  }, []);

  const queryClient = useQueryClient();
  const { membershipId } = useParams();
  const { data, isLoading, isError, isSuccess } = useMembership(
    membershipId || ""
  );
  const token = useAuthToken();
  const navigate = useNavigate();
  const [globalError, setGlobalError] = React.useState<string | undefined>(
    undefined
  );
  const [cancelReason, setCancelReason] = React.useState("");
  const [cancelReasonDetail, setcancelReasonDetail] = React.useState("");
  const [pauseMonths, setPauseMonths] = React.useState("1");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = (formValues) => {
    if (data && data.membershipId) {
      membershipApi
        .cancelMembership(
          data?.membershipId,
          token!,
          cancelReason,
          cancelReasonDetail,
          formValues.additionalReason
        )
        .then((response) => {
          if (response.ok) {
            queryClient.setQueryData(["membership"], () => undefined);
            if (window.analytics) {
              window.analytics.track("Membership Cancelled", {
                membershipPlanBefore: data,
                cancelType: "member",
                cancelReason: cancelReason,
                cancelReasonDetail: cancelReasonDetail,
                cancelAdditionalReason: formValues.additionalReason,
                source: "memberPortal",
                fbp: GetFBPcookie(),
              });
            }
            navigate("/my-account");
          } else {
            setGlobalError(
              "Could not process membership cancel request. Please contact support@litesport.com"
            );
          }
        });
    } else {
    }
  };

  if (isSuccess && data === undefined) {
    return <Navigate to={"/home"} />;
  } else if (isLoading) {
    return (
      <PageContainer>
        <Title>Cancel Membership</Title>
        <div>Loading...</div>
      </PageContainer>
    );
  } else if (isError) {
    return (
      <PageContainer>
        <Title>Cancel Membership</Title>
        <ErrorAlert
          heading={
            "Could retrieve membership information. Please contact support@litesport.com"
          }
        />
      </PageContainer>
    );
  } else {
    return (
      <>
        <ScrollToTop />
        <PageContainer>
          <Title>Cancel Membership</Title>
          {globalError ? (
            <ErrorAlert
              heading={globalError}
              onClose={() => {
                setGlobalError(undefined);
              }}
            />
          ) : null}
          <form onSubmit={handleSubmit(onSubmit)}>
            <label
              htmlFor="cancel-reason"
              className="block text-sm font-medium text-gray-700 mt-2"
            >
              Membership Cancellation Reason{" "}
              <span className={"text-red-700 "}>*</span>
            </label>
            <select
              className={cx(
                "mt-1 block w-full pl-3 pr-10 py-2 text-base  focus:outline-none   sm:text-sm rounded-md",
                {
                  "border-gray-300 focus:ring-green-500 focus:border-green-500":
                    !errors.reason,
                },
                {
                  "border-red-300 focus:ring-red-500 focus:border-red-500":
                    !!errors.reason,
                }
              )}
              defaultValue=""
              {...register("reason", {
                required: true,
                onChange: (e) => {
                  setCancelReason(e.target.value);
                  setcancelReasonDetail("");
                  setPauseMonths("1");
                },
              })}
            >
              <option></option>
              <option>Cost Related</option>
              <option>Loss of Interest / Not Enough Time</option>
              <option>Injury / Illness</option>
              <option>Traveling / Re-Location</option>
              <option>Technical Issues</option>
              <option>Other</option>
            </select>
            {errors.reason && errors.reason.type === "required" && (
              <InputError id="reason-error">This field is required</InputError>
            )}
            {cancelReason !== "" && cancelReason !== "Other" && (
              <>
                <label
                  htmlFor="cancel-reason-detail"
                  className="block text-sm font-medium text-gray-700 mt-2"
                >
                  {cancelReason === "Loss of Interest / Not Enough Time" && (
                    <>What’s the main reason that you’re not using Litesport?</>
                  )}
                  {cancelReason === "Injury / Illness" && (
                    <>
                      Thanks for letting us know. We wish you the best in your
                      recovery.{" "}
                    </>
                  )}
                  {cancelReason === "Technical Issues" && (
                    <>
                      Our Support team is ready to help. If it's a hardware
                      issue, your Liteboxer's warranty may cover the issue.
                      Would you like to get in contact with our team?
                    </>
                  )}
                  {(cancelReason === "Cost Related" ||
                    cancelReason === "Traveling / Re-Location" ||
                    cancelReason === "Injury / Illness") && (
                    <>What best describes your situation?</>
                  )}
                  <span className={"text-red-700 "}>*</span>
                </label>
                <select
                  id="reasonDetail"
                  className={cx(
                    "mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none   sm:text-sm rounded-md",
                    {
                      "border-gray-300 focus:ring-green-500 focus:border-green-500":
                        !errors.reason,
                    },
                    {
                      "border-red-300 focus:ring-red-500 focus:border-red-500":
                        !!errors.reason,
                    }
                  )}
                  defaultValue=""
                  {...register("reasonDetail", {
                    required: true,
                    onChange: (e) => {
                      if (
                        e.target.value === "Yes, I'd like to chat with Support"
                      ) {
                        window.location.replace(
                          "https://support.liteboxer.com/hc/en-us/requests/new"
                        );
                      } else {
                        setcancelReasonDetail(e.target.value);
                      }
                    },
                  })}
                >
                  <option></option>
                  {cancelReason === "Cost Related" && (
                    <>
                      <option>
                        I'd like to save money and plan to rejoin later
                      </option>
                      <option>
                        I’d like to save money and do not plan to rejoin
                      </option>
                      <option className={cx("flex flex-wrap")}>
                        I don't find enough value in the content/workouts
                      </option>
                    </>
                  )}
                  {cancelReason === "Loss of Interest / Not Enough Time" && (
                    <>
                      <option>
                        I don't enjoy boxing as much as I thought I would
                      </option>
                      <option>I can't find classes that I enjoy</option>
                      <option>I can’t find music / genres that I enjoy</option>
                      <option>I'm too busy to workout right now</option>
                      <option>Something else</option>
                    </>
                  )}
                  {cancelReason === "Injury / Illness" && (
                    <>
                      <option>
                        I need to take a break, but I plan to rejoin
                      </option>
                      <option>
                        I need to take a break, but do not plan to rejoin
                      </option>
                    </>
                  )}
                  {cancelReason === "Traveling / Re-Location" && (
                    <>
                      <option>
                        I won't have access to my VR headset and/or my Liteboxer
                      </option>
                      <option>
                        Not enough space for my Liteboxer in my new location
                      </option>
                      <option>Something else</option>
                    </>
                  )}
                  {cancelReason === "Technical Issues" && (
                    <>
                      <option>Yes, I'd like to chat with Support</option>
                      <option>No thanks, continue to cancel</option>
                    </>
                  )}
                </select>
                {[
                  "I'd like to save money and plan to rejoin later",
                  "I'm too busy to workout right now",
                  "I need to take a break, but I plan to rejoin",
                  "I won't have access to my VR headset and/or my Liteboxer",
                ].includes(cancelReasonDetail) &&
                  data?.status !== "PAUSED" &&
                  !data?.pauseOn &&
                  pauseMonths !== "0" && (
                    <>
                      <br />
                      <Title>Need a Break?</Title>
                      <br />
                      <p>
                        You may pause your membership for 1, 2, or 3 months,
                        beginning on your next bill date. You will not be billed
                        while your membership is paused. You may return here to
                        resume your membership at any time.
                        <br />
                      </p>
                      <select
                        className={cx(
                          "mt-1 block w-full pl-3 pr-10 py-2 text-base  focus:outline-none   sm:text-sm rounded-md"
                        )}
                        defaultValue="1"
                        {...register("months", {
                          required: true,
                          onChange: (e) => {
                            setPauseMonths(e.target.value);
                          },
                        })}
                      >
                        <option label="1 Month" value="1"></option>
                        <option label="2 Months" value="2"></option>
                        <option label="3 Months" value="3"></option>
                      </select>
                      <Button
                        type={"button"}
                        color={"green"}
                        className={"mt-2 mr-2"}
                        onClick={() => {
                          membershipApi
                            .pauseMembership(
                              data?.membershipId!,
                              token!,
                              pauseMonths
                            )
                            .then(() => {
                              navigate("/my-account");
                            });
                        }}
                      >
                        Pause Membership
                      </Button>
                      <Button
                        color={"red"}
                        onClick={() => {
                          setPauseMonths("0");
                        }}
                      >
                        No Thanks, continue to cancel
                      </Button>
                    </>
                  )}
              </>
            )}
            {(cancelReason === "Other" ||
              (cancelReason !== "" &&
                cancelReasonDetail !== "" &&
                (![
                  "I'd like to save money and plan to rejoin later",
                  "I'm too busy to workout right now",
                  "I need to take a break, but I plan to rejoin",
                  "I won't have access to my VR headset and/or my Liteboxer",
                ].includes(cancelReasonDetail) ||
                  data?.status === "PAUSED" ||
                  data?.pauseOn ||
                  pauseMonths === "0"))) && (
              <>
                <div className={"mt-2"}>
                  <label
                    htmlFor="additionalReason"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {cancelReason === "Other" ||
                    cancelReasonDetail === "Something else"
                      ? `Please provide additional detail:`
                      : `Anything else you'd like to share?`}
                  </label>
                  <div className="mt-1">
                    <textarea
                      {...register("additionalReason")}
                      rows={4}
                      name="additionalReason"
                      id="additionalReason"
                      className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                {cancelReasonDetail !== "Something else"}
                <Button type={"submit"} color={"red"} className={"mt-2 mr-2"}>
                  Cancel Membership
                </Button>
                <Button
                  color={"green"}
                  onClick={() => {
                    navigate("/my-account");
                  }}
                >
                  Keep Punching
                </Button>
              </>
            )}
          </form>
        </PageContainer>
      </>
    );
  }
};
