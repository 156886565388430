import { baseUrl } from "../../shared/Http";
import { useQuery } from "react-query";
import { useAuthToken } from "../../shared/AuthContext";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";

export type PaymentTokenResponse = {
  clientToken: string;
};

export const usePaymentToken = () => {
  const token = useAuthToken();
  if (!token) {
    throw new Error("could not authenticate");
  }
  return useQuery<PaymentTokenResponse>(
    ["braintree-token", "auth"],
    async () => {
      const response = await fetch(`${baseUrl}/memberships/payment_token`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
      });
      return response.json();
    }
  );
};

export const postPayment = async (
  payment: {
    nonce: string;
    planId: string;
    offerId?: string | null;
    captchaToken: string;
    fbp: string;
  },
  token: string
) => {
  return await fetch(`${baseUrl}/memberships`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify({
      nonce: payment.nonce,
      planId: payment.planId,
      offerId: payment.offerId,
      captchaToken: payment.captchaToken,
      fbp: GetFBPcookie(),
    }),
  });
};

export const updatePaymentMethod = ({
  token,
  nonce,
  membershipId,
}: {
  token: string;
  nonce: string;
  membershipId: string;
}) => {
  return fetch(`${baseUrl}/memberships/payment_method/${membershipId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify({ nonce }),
  });
};

export const findTransactions = (token: string) => {
  return fetch(`${baseUrl}/transactions`, {
    method: "GET",
    headers: {
      "Content-Type": "text/html",
      Authorization: `Bearer ${token}`,
    },
    referrerPolicy: "no-referrer",
  });
};
