import { baseUrl } from "../../shared/Http";

export type Profile = {
  id: number;
  email: string;
  displayName: string;
  firstName: string;
  lastName: string;
};

export const getProfile: (token: string) => Promise<Profile> = (
  token: string
) => {
  return fetch(`${baseUrl}/profiles`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer",
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("could not fetch profile");
    }
  });
};
