import * as React from "react";

type Props = {
  children: React.ReactNode;
};

export const PageContainer = ({ children }: Props) => {
  return (
    <>
      <div className={"px-4 sm:px-6 lg:px-8 mx-auto max-w-3xl mt-4"}>
        <div>{children}</div>
      </div>
    </>
  );
};
