import * as React from "react";
import { PageContainer } from "../../shared/PageContainer";
import { useNavigate } from "react-router";
import { Navigate } from "react-router-dom";
import { useAuthToken } from "../../shared/AuthContext";
import { PlanTypes, ShopifyData } from "./PlanApi";
import { Title } from "../../shared/Title";
import classes from "./GoPlanSelectionPage.module.css";
import { useSearchParams } from "react-router-dom";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";
import {
  Product,
  Cart,
  CheckoutState,
  useCatalog,
  addToCart,
  planTypeToGroup,
  useStoreToken,
  getProducts,
  getCurrencyFormatter,
  findFirstSubscriptionChild,
} from "../store/StoreApi";
import { useProfile } from "../profile/UseProfile";
import nl2br from "react-nl2br";

type PlanSelectionPageProps = {
  planType: PlanTypes;
};

const PlanSelectionPage = ({ planType }: PlanSelectionPageProps) => {
  React.useEffect(() => {
    if (window.analytics) {
      window.analytics.page("Members - Select Plan", {
        source: "memberPortal",
        fbp: GetFBPcookie(),
      });
      window.analytics.track("Product List Viewed", {
        source: "memberPortal",
        fbp: GetFBPcookie(),
      });
    }
  }, []);

  const [params] = useSearchParams();
  let rawShopifyData = params.get("data");
  if (rawShopifyData === null) {
    rawShopifyData = "";
  }
  let shopifyData: ShopifyData | undefined;
  if (rawShopifyData) {
    shopifyData = JSON.parse(atob(rawShopifyData)) as ShopifyData;
  }

  const { data: profile } = useProfile();
  let email: string | undefined;
  if (profile) {
    email = profile.email;
  } else if (shopifyData && shopifyData.email) {
    email = shopifyData.email;
  }

  const storeToken = useStoreToken();
  const { data: catalog } = useCatalog(
    storeToken,
    planTypeToGroup(planType),
    rawShopifyData
  );
  const products = getProducts(catalog);
  const formatter = getCurrencyFormatter();

  const PlanOption = ({ product }: { product: Product }) => {
    const subscription = findFirstSubscriptionChild(product);
    const navigate = useNavigate();
    // @TODO: Re-enable and use the other membership endpoint?
    // const { data: existingMembership, isSuccess } = useMemberships();
    // if (isSuccess && existingMembership !== undefined) {
    //   return <Navigate to={"/my-account"} replace={true} />;
    // }
    React.useEffect(() => {
      setTimeout(() => {
        if (window.analytics) {
          window.analytics.track("Product List Viewed", {
            list_id: PlanTypes[planType],
            fbp: GetFBPcookie(),
            // @TODO: Add more props here using data.plans.
          });
        }
      }, 2000);
    }, []);

    const goToCheckout = (productId: number) => {
      addToCart(storeToken, 0, product.id!, product.entitlementCode).then(
        (cart: Cart) => {
          let createAccount = false;
          if (typeof email === "undefined" && planType !== PlanTypes.Gift) {
            createAccount = true;
          }
          const state: CheckoutState = {
            cart: cart,
            email: email,
            createAccount: createAccount,
            data: rawShopifyData ? rawShopifyData : undefined,
            entitlementCode: product.entitlementCode,
            acceptsMarketing: shopifyData && shopifyData.accepts_marketing,
            sourceGroup: planTypeToGroup(planType),
          };

          navigate(`/checkout`, {
            state: state,
          });
        }
      );
    };

    return (
      <div
        className={`${classes.membershipContainer} border border-gray-300 mt-4`}
        onClick={() => {
          if (window.analytics) {
            window.analytics.track("Product Added", {
              product_id: product.id,
              name: product.name,
              price: product.price,
              source: "memberPortal",
              category: PlanTypes[planType],
              fbp: GetFBPcookie(),
            });
          }
          goToCheckout(product.id!);
        }}
      >
        {product.tag ? (
          <div className={classes.membershipTag}>{product.tag}</div>
        ) : null}
        <div className="bg-gray-200 p-4 py-6 md:grid md:grid-cols-2 min-h-[10rem]">
          <div>
            <h3
              className={
                "text-3xl uppercase font-semibold text-gray-900  tracking-wide "
              }
            >
              {product.name}
            </h3>
            <p className="mt-4">{nl2br(product.desc)}</p>
          </div>
          <div>
            <p className="text-left text-3xl font-bold uppercase pl-4">
              {formatter.format(product.checkoutPrice)}
            </p>
            {subscription &&
              subscription.initialMonthlyRate &&
              subscription.initialTermMonths && (
                <p className="text-left text-l italic pl-4">
                  {formatter.format(subscription.initialMonthlyRate)}/mo{" "}
                  {/* {subscription.initialTermMonths > 1 && "effective"} */}
                </p>
              )}
          </div>
        </div>
      </div>
    );
  };

  if (!products) {
    return <></>;
  }

  return (
    <PageContainer>
      <h3 className={"text-5xl uppercase"}>
        <Title>
          {planType === PlanTypes.AnonymousGO ||
          planType === PlanTypes.AuthenticatedGO
            ? "Choose Liteboxer Go Membership"
            : "Choose Litesport Membership"}
        </Title>
      </h3>
      <div className={"px-4 sm:px-6 lg:px-8 mx-auto"}>
        {products.map((product) => {
          return <PlanOption product={product} key={product.id} />;
        })}
        {/* {planType === PlanTypes.AnonymousGO && (
          <div className={"mt-20 text-center text-gray-700"}>
            Already an All-Access Member?{" "}
            <Link to="/go_upgrade" className="underline text-lb-green">
              Get your Liteboxer Go here.
            </Link>
          </div>
        )} */}
      </div>
    </PageContainer>
  );
};

export const GoPlanSelectionPage = () => {
  return <PlanSelectionPage planType={PlanTypes.AnonymousGO} />;
};

export const GiftPlanSelectionPage = () => {
  return <PlanSelectionPage planType={PlanTypes.Gift} />;
};

export const PostCheckoutPlanSelectionPage = () => {
  return <PlanSelectionPage planType={PlanTypes.PostCheckoutLB} />;
};

// export const GoUpgradePlanSelectionPage = () => {
//   const token = useAuthToken();

//   return (
//     <>
//       {token ? (
//         <PlanSelectionPage planType={PlanTypes.AuthenticatedGO} />
//       ) : (
//         <PageContainer>
//           <h3 className={"text-5xl uppercase"}>
//             <Title>Log In For Liteboxer Go Savings</Title>
//           </h3>
//           <div className={"px-4 sm:px-6 lg:px-8 mx-auto"}>
//             <LoginWidget />
//           </div>
//         </PageContainer>
//       )}
//     </>
//   );
// };

export const AuthenticatedLBPlanSelectionPage = () => {
  const token = useAuthToken();

  if (!token) {
    return <Navigate to="/login" />;
  }

  return <PlanSelectionPage planType={PlanTypes.AuthenticatedLB} />;
};
