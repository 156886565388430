import * as React from "react";
// import cx from "classnames";
import {
  useCatalog,
  useStoreToken,
  getProducts,
  Product,
  getCurrencyFormatter,
  CatalogGroups,
} from "../store/StoreApi";

type ChoosePlanPageProps = {
  choose: (p: Product) => void;
};

export const LiteboxerGoChoosePlanPage = ({ choose }: ChoosePlanPageProps) => {
  const token = useStoreToken();
  const { data } = useCatalog(token, CatalogGroups.GO);
  const products = getProducts(data);
  const formatter = getCurrencyFormatter();
  if (typeof products !== "undefined") {
    if (products.length === 1) {
      choose(products[0]);
    }
  }

  if (!products) {
    return <></>;
  }

  const extractSave = (p: Product) => {
    if (p.comparePrice) {
      return `SAVE ${formatter.format(p.comparePrice - p.price)}`;
    }
    return "";
  };

  return (
    <>
      <div className="px-2 lg:px-8">
        <h1 className="font-headline text-1.5xl uppercase font-black text-center mt-10">
          Choose Membership
        </h1>
        <h4 className="font-headline text-sm text-center mt-3 mb-9">
          Liteboxer Go included.
        </h4>
        {products.map((product) => {
          return (
            <React.Fragment key={product.id}>
              <div
                className="bg-lb-go-dark-gray m-6 p-4 drop-shadow-lg block cursor-pointer"
                onClick={() => choose(product)}
              >
                <div>
                  <h2 className="text-xl uppercase font-headline font-black text-white tracking-wide">
                    {product.name}
                  </h2>
                  <div className="flex">
                    <p className="mt-4 text-xs font-headline font-bold uppercase text-white flex-1">
                      {extractSave(product)}
                    </p>
                    <div className="flex-none pr-3">
                      <div className="text-xl font-headline font-bold mt-2 text-white">
                        ${product.checkoutPrice}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {products.length === 1 && (
                <button
                  onClick={() => choose(product)}
                  className="py-1.5 w-52 uppercase text-lg font-bold bg-lb-green text-white font-headline block mx-auto my-4"
                >
                  Buy Now
                </button>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <div className="bg-white">
        <h2 className="font-headline text-1.5xl uppercase font-black text-center mt-12 pt-6 pb-3">
          FAQs
        </h2>
        <div className="font-sans uppercase font-bold text-sm tracking-wide">
          <div className="flex border-t border-lb-go-dark-gray">
            <div className="grow p-4">What is included in my membership?</div>
            <div className="flex-initial py-4 pr-6 my-auto">+</div>
          </div>
          <div className="flex border-t border-lb-go-dark-gray">
            <div className="grow p-4">
              What is included in my Liteboxer kit?
            </div>
            <div className="flex-initial py-4 pr-6 my-auto">+</div>
          </div>
          <div className="flex border-t border-lb-go-dark-gray">
            <div className="grow p-4">Why is Liteboxer Go a membership?</div>
            <div className="flex-initial py-4 pr-6 my-auto">+</div>
          </div>
          <div className="flex border-t border-lb-go-dark-gray">
            <div className="grow p-4">Can I lower my monthly payment cost?</div>
            <div className="flex-initial py-4 pr-6 my-auto">+</div>
          </div>
          <div className="flex border-y border-lb-go-dark-gray">
            <div className="grow p-4">
              What is the commitment period on the monthly membership?
            </div>
            <div className="flex-initial py-4 pr-6 my-auto">+</div>
          </div>
        </div>
      </div>
    </>
  );
};
