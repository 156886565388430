import * as React from "react";
import {
  Product,
  Cart,
  CheckoutState,
  CatalogGroups,
  getCurrencyFormatter,
  useStoreToken,
  addToCart,
} from "../store/StoreApi";
import { useNavigate } from "react-router-dom";
import nl2br from "react-nl2br";

type CartPageProps = {
  product?: Product;
  prev?: () => void;
  showButtons?: boolean;
};

export const LiteboxerGoCartPage = ({
  product,
  prev,
  showButtons,
}: CartPageProps) => {
  const token = useStoreToken();
  const navigate = useNavigate();

  if (!product) {
    return <></>;
  }

  const goToCheckout = () => {
    addToCart(token, 0, product.id!).then((cart: Cart) => {
      // @TODO: Set this in localStorage or similar
      const state: CheckoutState = {
        cart: cart,
        createAccount: true,
        sourceGroup: CatalogGroups.GO,
      };
      navigate(`/checkout`, {
        state: state,
      });
    });
  };

  const formatter = getCurrencyFormatter();

  type CartItemProps = { item: Product };
  const CartItem = ({ item }: CartItemProps) => {
    return (
      <div className="flex mt-8">
        <div className="flex-initial w-20 px-3 align-top">
          <img
            src={process.env.PUBLIC_URL + item.image}
            alt="Liteboxer Go Membership"
          />
        </div>
        <div className="flex-1">
          <h2 className="font-headline text-sm font-bold uppercase">
            {item.name}
          </h2>
          <div className="text-sm">{nl2br(item.desc)}</div>
        </div>
        <div className="flex-initial px-3 font-headline text-sm text-right">
          {item.comparePrice && (
            <>
              <span className="line-through">
                {formatter.format(item.comparePrice)}
              </span>
              <br />
              &nbsp;
            </>
          )}
          {item.checkoutPrice === 0
            ? "FREE"
            : formatter.format(item.checkoutPrice)}
        </div>
      </div>
    );
  };

  return (
    <>
      <h1 className="font-headline text-4xl uppercase font-black text-center mt-10 leading-10">
        Join
        <br className="block md:hidden" /> Liteboxer
      </h1>
      <h2 className="font-headline text-2xl uppercase font-black text-center mt-2 leading-10">
        for just $18.99 per month
      </h2>
      {product.includes?.map((i) => (
        <React.Fragment key={i.sku}>
          <CartItem item={i} />
        </React.Fragment>
      ))}
      <div className="flex flex-wrap mt-10 pl-3 font-headline uppercase text-xs text-lb-go-cart-gray">
        <div className="my-2 grow text-lb-go-cart-light-gray">Subtotal</div>
        <div className="my-2 flex-initial mx-3 text-sm">
          {formatter.format(product.comparePrice!)}
        </div>
        <div className="basis-full h-0"></div>
        <div className="my-2 grow text-lb-go-cart-light-gray">Discount</div>
        <div className="my-2 flex-initial mx-3 text-sm">
          {formatter.format(product.comparePrice! - product.checkoutPrice)}
        </div>
        <div className="basis-full h-0"></div>
        <div className="my-2 grow text-lb-go-cart-light-gray">Shipping</div>
        <div className="my-2 flex-initial mx-3 text-sm border-lb-go-border-gray border-b w-8 border-dotted"></div>
        <div className="basis-full h-0"></div>
        <div className="my-2 grow text-lb-go-cart-light-gray">Tax</div>
        <div className="my-2 flex-initial mx-3 text-sm border-lb-go-border-gray border-b w-8 border-dotted"></div>
        <div className="basis-full h-0"></div>
        <div className="my-2 grow text-lb-go-cart-light-gray">Total Due</div>
        <div className="my-2 flex-initial mx-3 text-sm">
          {formatter.format(product.checkoutPrice!)}
        </div>
        <div className="basis-full h-0"></div>
      </div>
      <div className="mx-3 my-5 border-b border-lb-go-dark-gray" />
      <button
        onClick={goToCheckout}
        className="py-1.5 w-52 uppercase text-lg font-bold bg-lb-green text-white font-headline block mx-auto my-4"
      >
        Checkout
      </button>
      {prev && (
        <button
          onClick={prev}
          className="py-1.5 w-52 uppercase text-lg font-bold bg-gray-400 text-white font-headline block mx-auto my-4"
        >
          Keep Shopping
        </button>
      )}
    </>
  );
};
