import * as React from "react";
import { LiteboxerGoCheckoutAddressForm } from "./LiteboxerGoCheckoutAddressForm";
import { LiteboxerGoCheckoutMiniForm } from "./LiteboxerGoCheckoutMiniForm";
import { LiteboxerGoCheckoutPaymentAnonymous } from "./LiteboxerGoCheckoutPayment";
import { LiteboxerGoCart } from "./LiteboxerGoCart";
import {
  Cart,
  CheckoutRequest,
  Order,
  CheckoutState,
  CatalogGroups,
  useStoreToken,
  checkoutByCartId,
  getEntitlementCodesFromOrder,
} from "../store/StoreApi";
import { LiteboxerGoSignupWidget } from "./LiteboxerGoSignupWidget";
import { PaymentSuccess } from "../payments/PaymentSuccess";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";
import { useLocation } from "react-router-dom";
import Logo from "../../shared/Logo";

enum CheckoutSteps {
  Information,
  Payment,
  Account,
  Confirmation,
}

const steps = [
  CheckoutSteps.Information,
  CheckoutSteps.Payment,
  CheckoutSteps.Account,
  CheckoutSteps.Confirmation,
];

type CheckoutStepProps = {
  visibleStep: number;
  title: string;
  hideBottomBorder?: boolean;
  children: React.ReactNode;
  currentStep: number;
};

const StepContent = ({
  visibleStep,
  title,
  children,
  hideBottomBorder,
  currentStep,
}: CheckoutStepProps) => {
  const headingClasses = "uppercase font-sans text-xs font-bold tracking-wider";
  return (
    <>
      <figure className={headingClasses}>
        {visibleStep + 1}. {title}
      </figure>
      {currentStep === visibleStep && children}
      {hideBottomBorder !== true ? (
        <div className="border-b border-lb-go-border-gray my-4"></div>
      ) : (
        <div className="mb-4"></div>
      )}
    </>
  );
};

export const LiteboxerGoCheckout = () => {
  const location = useLocation();
  let state = location.state as CheckoutState;

  const [currentStep, setStep] = React.useState(0);
  const [cart, setCart] = React.useState<Cart>(state.cart);
  const token = useStoreToken();

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setStep(currentStep + 1);
    }
    if (window.analytics) {
      window.analytics.track("Checkout Step Completed", {
        source: "memberPortal",
        step: currentStep,
        shipping_method: "USPS",
        fbp: GetFBPcookie(),
      });
      window.analytics.track("Checkout Step Viewed", {
        source: "memberPortal",
        step: currentStep + 1,
        shipping_method: "USPS",
        fbp: GetFBPcookie(),
      });
    }
  };

  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [acceptsMarketing, setAcceptsMarketing] = React.useState(false);
  const [order, setOrder] = React.useState<Order | undefined>(undefined);
  const [showThanks, setShowThanks] = React.useState<boolean>(false);

  const nextStepWithCheckout = (checkout: CheckoutRequest) => {
    if (checkout.shipTo) {
      setFirstName(checkout.shipTo.first_name);
      setLastName(checkout.shipTo.last_name);
    }
    setEmail(checkout.email);
    setAcceptsMarketing(checkout.acceptsMarketing);

    checkoutByCartId(token, cart.id, checkout, state.entitlementCode).then(
      (cart) => {
        setCart(cart);
        nextStep();
      }
    );
  };

  const nextStepWithOrder = (order: Order) => {
    setOrder(order);
    nextStep();
  };

  const nextStepWithThanks = () => {
    setShowThanks(true);
  };

  // Auto advance if coming from the VR Trial flow with all the information
  React.useEffect(() => {
    if (typeof cart !== "undefined" && typeof cart.id !== "undefined") {
      if (
        typeof state.createAccount !== "undefined" &&
        state.createAccount === false
      ) {
        if (
          typeof state.email !== "undefined" &&
          typeof state.acceptsMarketing !== "undefined"
        ) {
          setEmail(state.email);
          setAcceptsMarketing(state.acceptsMarketing);

          if (!cart.isShippingRequired) {
            nextStep();

            const checkout: CheckoutRequest = {
              email: state.email,
              acceptsMarketing: state.acceptsMarketing,
              shipTo: null,
              billTo: null,
            };
            checkoutByCartId(token, cart.id, checkout).then((c) => {
              setCart(c);
            });
          }
        }
      }
    }
  }, []); //eslint-disable-line

  // Send
  React.useEffect(() => {
    if (window.analytics) {
      window.analytics.page("Members - Checkout", {
        source: "memberPortal",
        fbp: GetFBPcookie(),
      });
      window.analytics.track("Checkout Started", {
        source: "memberPortal",
        fbp: GetFBPcookie(),
      });
      window.analytics.track("Checkout Step Viewed", {
        source: "memberPortal",
        step: currentStep,
        shipping_method: "USPS",
        fbp: GetFBPcookie(),
      });
    }
  }, [currentStep]);

  const finalStep = () => {
    switch (state.sourceGroup) {
      case CatalogGroups.VR:
        return (
          <StepContent
            currentStep={currentStep}
            title="Jump Into Your Headset"
            visibleStep={CheckoutSteps.Account}
          >
            <>
              <div className="text-sans text-sm text-center">
                You're ready to jump into VR!
              </div>
              <button
                className="flex my-3 w-48 font-headline text-xs font-black uppercase text-gray-100 justify-center py-1.5 px-3 border border-transparent rounded-sm shadow-sm bg-lb-green hover:bg-black hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mx-auto"
                onClick={() =>
                  (window.location.href =
                    "https://www.oculus.com/experiences/quest/4175028349266073/")
                }
              >
                Download The App
              </button>
            </>
          </StepContent>
        );
      case CatalogGroups.Gift:
        if (state.createAccount) {
          // LOL NO
          return <></>;
        } else {
          return (
            <StepContent
              currentStep={currentStep}
              title="Get Your Gift Code"
              visibleStep={CheckoutSteps.Account}
            >
              <div className="my-4">
                <div className="text-sans text-sm text-center">
                  You have a gift code ready to share for Litesport VR Premium
                  membership!
                </div>
                {order &&
                  getEntitlementCodesFromOrder(order).map((c) => {
                    return (
                      <div className="text-2xl py-4 text-lb-green text-center font-bold font-sans">
                        {c}
                      </div>
                    );
                  })}
                <div className="text-sans text-sm text-center">
                  Please note this for your records.
                </div>
              </div>
            </StepContent>
          );
        }
      default:
        if (state.createAccount) {
          return (
            <StepContent
              currentStep={currentStep}
              title="Create Litesport Account"
              visibleStep={CheckoutSteps.Account}
            >
              {showThanks ? (
                <PaymentSuccess />
              ) : (
                <LiteboxerGoSignupWidget
                  onSuccess={nextStepWithThanks}
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  canChangeEmail={false}
                />
              )}
            </StepContent>
          );
        } else {
          return (
            <StepContent
              currentStep={currentStep}
              title="Get Started"
              visibleStep={CheckoutSteps.Account}
            >
              <PaymentSuccess />
            </StepContent>
          );
        }
    }
  };

  return (
    <div className="min-h-screen bg-lg-go-light-gray relative">
      <Logo />

      <div className={"px-4 pt-40 sm:px-6 lg:px-8 mx-auto"}>
        <div className="my-3">
          <LiteboxerGoCart
            cart={cart}
            orderPlaced={typeof order !== "undefined"}
          />
        </div>
        <StepContent
          currentStep={currentStep}
          title={
            typeof state.createAccount !== "undefined" &&
            state.createAccount === false
              ? "Enter Contact Details"
              : "Enter Shipping Details"
          }
          visibleStep={CheckoutSteps.Information}
        >
          {cart.isShippingRequired ? (
            <LiteboxerGoCheckoutAddressForm
              email={state.email}
              acceptsMarketing={state.acceptsMarketing}
              onSuccess={nextStepWithCheckout}
            />
          ) : (
            <LiteboxerGoCheckoutMiniForm
              email={state.email}
              onSuccess={nextStepWithCheckout}
            />
          )}
        </StepContent>
        <StepContent
          currentStep={currentStep}
          title="Enter Payment Method"
          visibleStep={CheckoutSteps.Payment}
        >
          <>
            <LiteboxerGoCheckoutPaymentAnonymous
              token={token}
              cart={cart}
              email={email}
              acceptsMarketing={acceptsMarketing}
              onSuccess={nextStepWithOrder}
            />
          </>
        </StepContent>
        {finalStep()}
      </div>
    </div>
  );
};
