import React from "react";
import "./App.css";
import { LandingPage } from "./features/landing/LandingPage";
import { Route, Routes } from "react-router";
import { LoginPage } from "./features/auth/LoginPage";
import { SignupPage } from "./features/auth/SignupPage";
import { MyAccount } from "./features/profile/MyAccount";
import { ContentSection } from "./shared/ContentSection";
import { SegmentAnalytics } from "./shared/SegmentAnalytics";
import { LogRocketReact } from "./shared/LogRocket";
import {
  PostCheckoutPlanSelectionPage,
  AuthenticatedLBPlanSelectionPage,
} from "./features/plan/GoPlanSelectionPage";
import { PlacesJS } from "./features/go-checkout/LiteboxerGoCheckoutAddressForm";
import { Trial, TrialExistingUser } from "./features/vr/Trial";
import { LiteboxerGoLandingPage } from "./features/go-checkout/LiteboxerGoLandingPage";
import { LiteboxerGoCheckout } from "./features/go-checkout/LiteboxerGoCheckout";
import { GiftLandingPage } from "./features/gift/GiftLandingPage";
import { ManagePlanPage } from "./features/plan/ManagePlanPage";
import { UpgradePlanPage } from "./features/plan/UpgradePlanPage";
import { CancelPlanPage } from "./features/plan/CancelPlanPage";
import { PausePlanPage } from "./features/plan/PausePlanPage";
import { PlanChangeCheckout } from "./features/plan/PlanChangeCheckout";

function App() {
  return (
    <React.Fragment>
      <SegmentAnalytics writeKey={process.env.REACT_APP_SEGMENT_WRITE_KEY} />
      <PlacesJS apiKey="AIzaSyCfkNTL8C25va3uL6KMjY4ld0-stLAglAU" />
      <LogRocketReact projectId="aw7g7v/members" />
      <Routes>
        <Route path={"/"} element={<LandingPage />} />
        <Route path={"/login"} element={<LoginPage />} />
        <Route path={"/signup"} element={<SignupPage />} />
        <Route
          path={"/my-account"}
          element={
            <ContentSection>
              <MyAccount />
            </ContentSection>
          }
        />

        <Route path={"/gift"} element={<GiftLandingPage />} />
        <Route path={"/lb"} element={<PostCheckoutPlanSelectionPage />} />
        <Route
          path={"/lb_member"}
          element={<AuthenticatedLBPlanSelectionPage />}
        />
        <Route path={"/vr_trial"} element={<Trial isVariation={true} />} />
        <Route path={"/vr-trial"} element={<Trial isVariation={true} />} />
        <Route path={"/vr_premium_trial"} element={<TrialExistingUser />} />
        <Route path={"/lb_go"} element={<LiteboxerGoLandingPage />} />
        <Route
          path={"/membership/:membershipId/manage"}
          element={<ManagePlanPage />}
        />
        <Route
          path={"/membership/:membershipId/upgrade"}
          element={<UpgradePlanPage />}
        />
        <Route
          path={"/membership/:membershipId/cancel"}
          element={
            <ContentSection>
              <CancelPlanPage />
            </ContentSection>
          }
        />
        <Route
          path={"/membership/:membershipId/pause"}
          element={
            <ContentSection>
              <PausePlanPage />
            </ContentSection>
          }
        />

        <Route path={"/checkout"} element={<LiteboxerGoCheckout />} />
        <Route
          path={"/memberships/:membershipId/checkout"}
          element={<PlanChangeCheckout />}
        />
      </Routes>
    </React.Fragment>
  );
}

export default App;
