import * as React from "react";
import styles from "./VideoBackground.module.css";
import cx from "classnames";

export type VideoBackgroundProps = {
  cdnFilename: string;
  breakpointWidth: number;
  className?: string;
};

export const VideoBackground = ({
  cdnFilename,
  breakpointWidth,
  className,
}: VideoBackgroundProps) => {
  const mobileVideoSrc = `https://assets.prod.liteboxer.net/static/video/${cdnFilename}-vertical.mp4`;
  const desktopVideoSrc = `https://assets.prod.liteboxer.net/static/video/${cdnFilename}-horizontal.mp4`;
  const [src, setSrc] = React.useState(mobileVideoSrc);
  const invalidProps = {
    allow: "autoplay; fullscreen",
    frameborder: 0,
  };

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > breakpointWidth) {
        setSrc(desktopVideoSrc);
      } else {
        setSrc(mobileVideoSrc);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  // @ts-ignore
  return (
    <video
      className={cx(className, styles.videoBackground)}
      autoPlay
      muted
      loop
      playsInline
      src={src}
      {...invalidProps}
    ></video>
  );
};
