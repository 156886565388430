import * as React from "react";

type TitleProps = {
  children: React.ReactNode;
};
export const Title = ({ children }: TitleProps) => {
  return (
    <h3 className={"text-2xl font-semibold text-gray-800 text-center "}>
      {children}
    </h3>
  );
};
