import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { useEffect } from "react";

interface LogRocketProps {
  projectId: string;
}

export const LogRocketReact = ({ projectId }: LogRocketProps) => {
  useEffect(() => {
    // after calling LogRocket.init()
    LogRocket.init(projectId);
    setupLogRocketReact(LogRocket);
  }, [projectId]);

  return null;
};
