import { useEffect } from "react";
import { RECAPTCHA_KEY } from "../../shared/EnvVariables";

export const getCaptchaToken = (): Promise<string> => {
  return window.grecaptcha.execute(RECAPTCHA_KEY, {
    action: "submit",
  });
};

export const useCaptcha = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
};
