import * as React from "react";
import { PageContainer } from "../../shared/PageContainer";
import { useMemberships, useTransactionsHtml } from "../plan/PlanApi";
import { usePaymentToken } from "../payments/PaymentApi";
import { ErrorAlert } from "../../shared/ErrorAlert";
import { useNavigate } from "react-router-dom";
import { PaymentCardInput } from "../payments/PaymentCardInput";
import { Button } from "../../shared/Button";
import * as paymentApi from "../payments/PaymentApi";
import { useAuthToken } from "../../shared/AuthContext";
import { useQueryClient } from "react-query";
import { Title } from "../../shared/Title";
import { useProfile } from "./UseProfile";
import { ScrollToTop } from "../../shared/ScrollToTop";
import { format } from "date-fns";
import { GetFBPcookie } from "../../shared/SegmentAnalytics";
import { getCurrencyFormatter } from "../store/StoreApi";
const formatter = getCurrencyFormatter();

export const MyAccount = () => {
  React.useEffect(() => {
    if (window.analytics) {
      window.analytics.page("Members - Account", {
        source: "memberPortal",
        fbp: GetFBPcookie(),
      });
    }
  }, []);
  const navigate = useNavigate();
  const { data: profile } = useProfile();
  const { data: memberships } = useMemberships();
  const [paymentInstance, setPaymentInstance] = React.useState<any>(undefined);
  const [updatePaymentInfo, setUpdatePaymentInfo] = React.useState("");
  const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] =
    React.useState(false);
  const client = useQueryClient();
  const token = useAuthToken()!;
  const onPaymentChange = React.useCallback((instance) => {
    setPaymentInstance(instance);
  }, []);
  const [membershipWithError, setMembershipWithError] = React.useState("");
  const [membershipError, setMembershipError] = React.useState<
    string | undefined
  >(undefined);
  const paymentTokenQuery = usePaymentToken();
  const { data: transactionsHtml } = useTransactionsHtml();
  if (memberships === undefined) {
    // Show Plan choices? //
    //return <Navigate to={"/home"} />;
  }
  return (
    <>
      <ScrollToTop />
      <PageContainer>
        <Title>My Account</Title>
        <div>
          <div className="mt-10 divide-y divide-gray-200">
            <div className="space-y-1">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Profile
              </h3>
            </div>
            <div className="mt-6">
              <dl className="divide-y divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">
                      {profile
                        ? `${profile.firstName} ${profile.lastName}`
                        : null}
                    </span>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">
                      {profile ? profile.email : null}
                    </span>
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                  <dt className="text-sm font-medium text-gray-500">
                    User Name
                  </dt>
                  <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="flex-grow">
                      {profile ? profile.displayName : null}
                    </span>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          {memberships && (
            <div className="mt-10 divide-y divide-gray-200">
              <div className="space-y-1">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  My Memberships
                </h3>
              </div>
              {memberships
                ? memberships.memberships.map((i) => {
                    return (
                      <React.Fragment key={i.id}>
                        <div className="mt-6 sm:mt-2">
                          <dl className="divide-y divide-gray-200">
                            <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                              <dt className="text-sm font-medium text-gray-500">
                                Plan
                              </dt>
                              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="flex-grow">
                                  {i.planType} - {i.planName}
                                </span>
                                <div>
                                  {i.membershipId &&
                                    i.planType !== "Liteboxer VR (Oculus)" &&
                                    i.planType !== "Litesport VR (Oculus)" && (
                                      <Button
                                        color={"black"}
                                        onClick={() => {
                                          navigate(
                                            `/membership/${i.id}/manage`
                                          );
                                        }}
                                      >
                                        MANAGE
                                      </Button>
                                    )}
                                </div>
                              </dd>
                            </div>
                            <div className="py-2 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:mt-0">
                              <dt className="text-sm font-medium text-gray-500">
                                Status
                              </dt>
                              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="flex-grow">
                                  {i.status}
                                  {i.status === "PAUSED" && i.pauseUntil && (
                                    <>
                                      {" until "}
                                      {i.pauseUntil}
                                    </>
                                  )}
                                  {i.status !== "PAUSED" &&
                                    i.status !== "CANCELLED" &&
                                    i.pauseOn &&
                                    i.pauseUntil && (
                                      <>
                                        {" "}
                                        until {i.pauseOn}
                                        <br></br>then PAUSED until{" "}
                                        {i.pauseUntil}
                                      </>
                                    )}
                                </span>
                              </dd>
                            </div>
                            <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-2">
                              <dt className="text-sm font-medium text-gray-500">
                                {i.payPalAccountEmail &&
                                i.payPalAccountEmail !== ""
                                  ? "PayPal Account"
                                  : ""}
                                {i.planType === "Liteboxer VR (Oculus)" ||
                                i.planType === "Litesport VR (Oculus)"
                                  ? "Paid via"
                                  : "Credit Card on File"}
                              </dt>
                              {updatePaymentInfo === i.id ? (
                                <dd className="mt-1  text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                  {paymentTokenQuery.isLoading ? (
                                    <div>Loading...</div>
                                  ) : paymentTokenQuery.data === undefined ? (
                                    <div>Something went wrong</div>
                                  ) : (
                                    <PaymentCardInput
                                      onPaymentInstanceChange={onPaymentChange}
                                      braintreeToken={
                                        paymentTokenQuery.data.clientToken
                                      }
                                    />
                                  )}
                                  <div className={"mt-2"}>
                                    <Button
                                      className={"mr-2"}
                                      color={"green"}
                                      disabled={isUpdatingPaymentMethod}
                                      onClick={() => {
                                        setIsUpdatingPaymentMethod(true);
                                        paymentInstance?.requestPaymentMethod(
                                          (err: any, payload: any) => {
                                            if (!err) {
                                              paymentApi
                                                .updatePaymentMethod({
                                                  nonce: payload.nonce,
                                                  token,
                                                  membershipId: i.id,
                                                })
                                                .then((response) => {
                                                  setIsUpdatingPaymentMethod(
                                                    false
                                                  );
                                                  if (response.ok) {
                                                    client.invalidateQueries([
                                                      "memberships",
                                                    ]);
                                                    setUpdatePaymentInfo("");
                                                  } else {
                                                    setMembershipError(
                                                      "Something unexcepted went wrong updating payment method. Please contact support@litesport.com"
                                                    );
                                                    setMembershipWithError(
                                                      i.id
                                                    );
                                                  }
                                                });
                                            } else {
                                              setMembershipError(
                                                "Something unexcepted went wrong updating payment method. Please contact support@litesport.com"
                                              );
                                              setIsUpdatingPaymentMethod(false);
                                              setMembershipWithError(i.id);
                                            }
                                          }
                                        );
                                      }}
                                    >
                                      {isUpdatingPaymentMethod
                                        ? "Updating Payment Method"
                                        : "Update Payment Method"}
                                    </Button>

                                    <Button
                                      color={"gray"}
                                      disabled={isUpdatingPaymentMethod}
                                      onClick={() => {
                                        setUpdatePaymentInfo("");
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    {membershipError &&
                                    membershipWithError === i.id ? (
                                      <ErrorAlert
                                        heading={membershipError}
                                        onClose={() => {
                                          setMembershipError(undefined);
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                </dd>
                              ) : (
                                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                  <div className={"flex"}>
                                    <img
                                      alt="Credit Card On File"
                                      className="tiny"
                                      src={i.cardImageUrl}
                                      style={{ width: "30px" }}
                                    />

                                    <div className="ml-2 mr-2 flex-grow">
                                      {i.payPalAccountEmail &&
                                      i.payPalAccountEmail !== ""
                                        ? i.payPalAccountEmail
                                        : ""}
                                      {i.cardLast4 && i.cardLast4 !== ""
                                        ? `ending in ${i?.cardLast4}`
                                        : ""}
                                    </div>
                                  </div>
                                  {i.planType !== "Liteboxer VR (Oculus)" &&
                                    i.planType !== "Litesport VR (Oculus)" && (
                                      <button
                                        type="button"
                                        className="underline text-blue-700"
                                        onClick={() => {
                                          setUpdatePaymentInfo(i.id);
                                        }}
                                      >
                                        Update
                                      </button>
                                    )}
                                </dd>
                              )}
                            </div>
                            <div className="py-2 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                              <dt className="text-sm font-medium text-gray-500">
                                Paid Through Date
                              </dt>
                              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="flex-grow">
                                  {i.paidThroughDate
                                    ? format(
                                        new Date(i.paidThroughDate),
                                        "MM/dd/y"
                                      )
                                    : ""}
                                </span>
                              </dd>
                            </div>
                            <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                              <dt className="text-sm font-medium text-gray-500">
                                {i.paidThroughDate === null
                                  ? "First Bill Date"
                                  : "Next Bill Date"}
                              </dt>
                              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="flex-grow">
                                  {i.nextBillDate
                                    ? format(
                                        new Date(i.nextBillDate),
                                        "MM/dd/y"
                                      )
                                    : i.planType === "Liteboxer VR (Oculus)" ||
                                      i.planType === "Litesport VR (Oculus)"
                                    ? "See Oculus for details"
                                    : ""}
                                  {/* {i.membershipId && (
                                  <div>
                                    {"  "}
                                    <Link
                                      to={`/membership/${
                                        i.id
                                      }/change_plan/${"term"}`}
                                      className="underline text-blue-700"
                                    >
                                      Update Frequency
                                    </Link>
                                  </div>
                                )} */}
                                </span>
                              </dd>
                            </div>
                            <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                              <dt className="text-sm font-medium text-gray-500">
                                Next Bill Amount
                              </dt>
                              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="flex-grow">
                                  {i.nextBillAmount
                                    ? formatter.format(i.nextBillAmount!)
                                    : i.planType === "Liteboxer VR (Oculus)" ||
                                      i.planType === "Litesport VR (Oculus)"
                                    ? "See Oculus for details"
                                    : " "}
                                </span>
                              </dd>
                            </div>
                            <div className="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
                              <dt className="text-sm font-medium text-gray-500">
                                {i.billingPeriodStartDate
                                  ? "Current Billing Period"
                                  : ""}
                              </dt>
                              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="flex-grow">
                                  {i.billingPeriodStartDate
                                    ? format(
                                        new Date(i.billingPeriodStartDate),
                                        "MM/dd/y"
                                      ) + " to "
                                    : " "}
                                  {i.billingPeriodEndDate
                                    ? format(
                                        new Date(i.billingPeriodEndDate),
                                        "MM/dd/y"
                                      )
                                    : " "}
                                </span>
                              </dd>
                            </div>
                          </dl>
                          <br></br>
                        </div>
                      </React.Fragment>
                    );
                  })
                : ""}
            </div>
          )}
          <div className="mt-10">
            <div className="space-y-1">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                My Billing History
              </h3>
            </div>
            <div className="mt-6">
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {transactionsHtml && (
                  <div dangerouslySetInnerHTML={{ __html: transactionsHtml }} />
                )}
              </dd>
            </div>
          </div>
        </div>
      </PageContainer>
    </>
  );
};
