import * as React from "react";
import cx from "classnames";

interface LiteboxerButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  isLoading?: boolean;
  icon?: React.ReactNode;
}

export const LiteboxerButton = ({
  text,
  className,
  isLoading,
  icon,
  ...rest
}: LiteboxerButtonProps) => {
  const fullClassNames = cx(
    "uppercase mt-3 w-full text-gray-100 font-semibold text-lg flex justify-center py-2 px-4 border border-transparent rounded-sm shadow-sm bg-lb-green hover:bg-black hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500",
    className
  );
  return (
    <button {...rest} className={fullClassNames} disabled={isLoading}>
      {isLoading ? (
        <svg
          className="animate-spin -ml-1 mr-3 h-6 w-6 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      ) : icon ? (
        icon
      ) : null}
      {text}
    </button>
  );
};
